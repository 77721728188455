import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { reportGarminOauthToken } from '../../../utils/api/api'
import { Redirect } from 'react-router-dom'

export const GarminCallback = () => {
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const [updateAndRedirect, setUpdateAndRedirect] = useState()

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const oauthtoken = urlParams.get('oauth_token')
        const oauthVerifier = urlParams.get('oauth_verifier')

        reportGarminOauthToken(token, oauthtoken, oauthVerifier).then(() => {
            setUpdateAndRedirect(true)
        })
    }, [token])

    if (updateAndRedirect) {
        return (
            <Redirect
                to={{
                    pathname: '/app/settings',
                }}
            />
        )
    }

    return (
        <>
            <h1>Welcome back!</h1>
        </>
    )
}
