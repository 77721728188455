export const UnitDisplayer = (props) => {
    var valueInSteps = props.valueInSteps
    var valueInTime = props.valueInTime
    var unit = props.unit
    const stepLength = 0.762

    if (unit === 'Km') {
        if (valueInSteps === undefined) return '-'
        return (
            Math.round(((valueInSteps * stepLength) / 1000) * 10) / 10 + ' km'
        )
    }
    if (unit === 'Points') {
        if (valueInSteps === undefined) return '-'
        return (
            Math.round(((valueInSteps * stepLength) / 1000) * 10) / 10 + ' pts'
        )
    }
    if (unit === 'Steps') {
        if (valueInSteps === undefined) return '-'
        if (valueInSteps < 999) return valueInSteps
        else if (valueInSteps > 1000 && valueInSteps < 999999)
            return Math.round((valueInSteps / 1000) * 10) / 10 + 'k'
        else return Math.round((valueInSteps / 1000000) * 100) / 100 + 'M'
    }
    if (unit === 'Meter') {
        if (valueInSteps === undefined) return '-'
        return valueInSteps * stepLength + ' m'
    }
    if (unit === 'Time') {
        if (valueInTime === undefined) return 0 + 'h'
        if (valueInTime < 60) return valueInTime + 'm'
        if (valueInTime === 60) return valueInTime / 60 + 'h'
        else {
            return (
                Math.floor(valueInTime / 60) +
                'h ' +
                (valueInTime % 60 !== 0 ? (valueInTime % 60) + 'm' : '')
            )
        }
    } else if (valueInSteps) return valueInSteps
    else return 0
}
