import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { setFirstTimeDone, getPeriodData } from '../../utils/api/api'
import { fetchUserConfig } from '../../utils/auth/thunks'
import './style.css'

export const FirstTimer = (props) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(props.open)
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const [registrationStart, setRegistrationStart] = useState()
    const [registrationEnd, setRegistrationEnd] = useState()

    const handleClose = () => {
        setFirstTimeDone(token).then(() => {
            dispatch(fetchUserConfig(token))
        })
        setOpen(false)
    }

    const renderWelcome = () => {
        if (new Date().toISOString() <= registrationStart) {
            return (
                <div>
                    Currently we are in the phase of getting people onboard. You
                    can start registering your activities from{' '}
                    {new Date(registrationStart).toLocaleDateString('en-US', {
                        dateStyle: 'full',
                    })}
                    .
                </div>
            )
        } else if (new Date().toISOString() >= registrationStart) {
            return (
                <div>
                    The current period started{' '}
                    {new Date(registrationStart).toLocaleDateString('en-US', {
                        dateStyle: 'full',
                    })}
                    , and you are therefore a little late to the party 😢 But do
                    not panic, on{' '}
                    {new Date(registrationEnd).toLocaleDateString('en-US', {
                        dateStyle: 'full',
                    })}{' '}
                    the current period ends, and another will be available to
                    you shortly after 🥳
                </div>
            )
        } else {
            return <div>Now, get going and add your first activity</div>
        }
    }

    useEffect(() => {
        getPeriodData(token).then((result) => {
            var { registrationStart, registrationEnd } = result.currentPeriod
            setRegistrationStart(registrationStart)
            setRegistrationEnd(registrationEnd)
        })
    }, [token])

    if (registrationStart !== undefined) {
        return (
            <>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        <center>
                            <i>Welcome</i>{' '}
                            <span role="img" aria-label="hi">
                                👋
                            </span>
                        </center>
                    </DialogTitle>
                    <DialogContent style={{ lineHeight: '1.9' }}>
                        <center>
                            Seems like this is your first time around - we are
                            very pleased to see you!
                            {renderWelcome()}
                        </center>
                        <div style={{ padding: '15px' }}>
                            <center>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleClose}
                                >
                                    OK
                                </Button>
                            </center>
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    } else return <>Loading ...</>
}
