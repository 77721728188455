import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { DataConfig } from './DataConfig'
import { PeriodConfig } from './PeriodConfig'
import { UserConfig } from './UserConfig'
import { TeamsConfig } from './TeamsConfig'
import { ActivityConfig } from './ActivityConfig'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        paddingTop: '70px',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}))

export const ConfigurationAccordion = () => {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography className={classes.heading}>
                        Data Management
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <DataConfig />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Periods</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <PeriodConfig />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Users</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <UserConfig />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>Teams</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TeamsConfig />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <Typography className={classes.heading}>
                        Activities
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ActivityConfig />
                </AccordionDetails>
            </Accordion>
        </div>
    )
}
