import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { MyActivities } from '../activity/myActivities'
import { makeStyles } from '@material-ui/core/styles'

import {
    fetchCategories,
    fetchGroupedActivityHistory,
    fetchUserConfig,
    fetchTeamData,
    fetchCompanyData,
} from '../../utils/auth/thunks'

import 'react-circular-progressbar/dist/styles.css'
import { SectorTabs } from './SectorTabs'
import { PersonalPageSummary } from './PersonalPageSummary'
import { MyTeam } from '../team/myTeam'
import { MyCompany } from '../company/myCompany'
import './style.css'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { Box } from '@material-ui/core'
import { FirstTimer } from './firstTimer'
import { Information } from './Information'
import { ActivateUser } from './activateUser'
import { getPeriodData } from '../../utils/api/api'
import { successPeriodData } from '../period/periodSlice'

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: '#0b1222',
        padding: theme.spacing(5, 0, 0),
    },
    heroButtons: {
        marginTop: theme.spacing(0),
    },
    team: {
        fontSize: '20px',
    },
    cardGrid: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    toolbarButtons: {
        marginLeft: 'auto',
    },
}))

export const HomePage = memo(() => {
    const dispatch = useDispatch()
    const { email } = useSelector((state) => state.auth.user)
    const { config } = useSelector((state) => state.user)
    const { currentPeriod } = useSelector((state) => state.period.data)
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    useEffect(() => {
        dispatch(fetchUserConfig(token, email))
        dispatch(fetchCategories(token))
        getPeriodData(token).then((result) => {
            var { registrationStart, registrationEnd } = result.currentPeriod
            dispatch(successPeriodData(result))
            dispatch(fetchTeamData(token, registrationStart, registrationEnd))
            dispatch(
                fetchCompanyData(token, registrationStart, registrationEnd)
            )
            dispatch(
                fetchGroupedActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
            )
        })
    }, [dispatch, token, email])

    const classes = useStyles()
    var windowWidth = window.innerWidth

    const renderInformation = () => {
        if (currentPeriod !== null || currentPeriod !== undefined) {
            if (new Date().toISOString <= currentPeriod?.registrationStart) {
                return (
                    <Information
                        text={`Only user registration is allowed at this stage. Activity registration starts ${new Date(
                            currentPeriod.registrationStart
                        ).toLocaleDateString('en-US', {
                            dateStyle: 'long',
                        })}`}
                    />
                )
            }
        }
    }

    const renderCompsWideScreen = () => {
        return (
            <Container className={classes.cardGrid} maxWidth="lg">
                <Box pt={12}></Box>
                <Box pt={2} id="period-slider">
                    <Grid container spacing={0} justify="center">
                        <Grid item xs={10} sm={10} md={10}>
                            {renderInformation()}
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={0}>
                    <Grid container spacing={4} justify="center">
                        <Grid item xs={4} sm={4} md={4}>
                            <ErrorBoundary token={token}>
                                <MyTeam />
                            </ErrorBoundary>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <ErrorBoundary token={token}>
                                <PersonalPageSummary />
                            </ErrorBoundary>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4}>
                            <ErrorBoundary token={token}>
                                <MyCompany />
                            </ErrorBoundary>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }

    if (config.firstTime) {
        return <FirstTimer open={true} />
    }

    return (
        <>
            {config && (
                <>
                    {config.status === 'Inactive' && (
                        <ActivateUser open={true} />
                    )}
                    {windowWidth <= 768 && <SectorTabs />}
                    {windowWidth > 768 && renderCompsWideScreen()}
                    <main style={{ minHeight: '75vh' }}>
                        <div>
                            <Container
                                className={classes.cardGrid}
                                maxWidth="lg"
                            >
                                <Grid container spacing={4} justify="center">
                                    <Grid item xs={12} sm={10} md={8}>
                                        <MyActivities />
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                    </main>
                </>
            )}
        </>
    )
})
