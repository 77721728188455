import { combineReducers } from '@reduxjs/toolkit'
import authSlice from '../utils/auth/authSlice'
import categorySlice from '../pages/category/categorySlice'
import activitySlice from '../pages/activity/activitySlice'
import userSlice from '../pages/user/userSlice'
import userSummarySlice from '../pages/user/userSummarySlice'
import teamSlice from '../pages/team/teamSlice'
import storageSession from 'redux-persist/lib/storage/session'
import companySlice from '../pages/company/companySlice'
import periodSlice from '../pages/period/periodSlice'

const auth = authSlice
const category = categorySlice
const activity = activitySlice
const user = userSlice
const team = teamSlice
const company = companySlice
const userSummary = userSummarySlice
const period = periodSlice

const appReducer = combineReducers({
    auth,
    category,
    activity,
    user,
    team,
    company,
    userSummary,
    period,
})

export const rootReducer = (state, action) => {
    if (action.type === 'auth/requestLogout') {
        // for all keys defined in your persistConfig(s)
        storageSession.removeItem('persist:root')

        state = undefined
    }
    return appReducer(state, action)
}
