import React, { useState } from 'react'
import { myFirebase } from '../../utils/auth/Firebase'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useDispatch } from 'react-redux'
import { signUpUser } from '../../utils/auth/thunks'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Error, Warning } from '../../components/ToastContent'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export const SignUpPage = () => {
    var emailDomain = process.env.REACT_APP_EMAIL_DOMAIN
    const classes = useStyles()
    const [email, setEmail] = useState()
    const [name, setName] = useState()
    const [password, setPassword] = useState()
    const [readPrivacy, setReadPrivacy] = useState(false)
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const { emailVerified } = useSelector((state) => state.auth.user)

    const dispatch = useDispatch()

    const handleReadPrivacy = () => {
        setReadPrivacy(!readPrivacy)
    }

    const signUp = (e) => {
        e.preventDefault()
        if (!name) {
            toast.warning(<Warning text="Name is missing" />)
            return
        }
        if (name === '' || name === ' ' || name === '  ' || name.length < 3) {
            toast.warning(
                <Warning text="Name cannot be blank and must be at least 3 characters" />
            )
            return
        }
        if (!email) {
            toast.warning(<Warning text="Email is missing" />)
            return
        }
        if (!password) {
            toast.warning(<Warning text="Password is missing" />)
            return
        }
        if (!readPrivacy) {
            toast.warning(
                <Warning text="Please verify that you have read the privacy notes" />
            )
            return
        } else {
            if (!email.includes(emailDomain)) {
                toast.warning(
                    <Warning
                        text={`You need to provide a ${emailDomain} email address`}
                    />
                )
            } else {
                dispatch(signUpUser(email, password, name)).then((response) => {
                    if (response !== undefined) {
                        toast.error(<Error text={response?.message} />)
                    } else {
                        myFirebase
                            .auth()
                            .currentUser.sendEmailVerification()
                            .then(() => {
                                console.log('Email sent')
                            })
                            .catch((error) => {
                                console.error(error)
                            })
                    }
                })
            }
        }
    }

    if (isAuthenticated && !emailVerified) {
        return (
            <Redirect
                to={{
                    pathname: 'verifyemail',
                }}
            />
        )
    }

    if (isAuthenticated && emailVerified) {
        return (
            <Redirect
                to={{
                    pathname: 'intro/selector',
                }}
            />
        )
    } else {
        return (
            <article>
                <div className="pt-2">
                    <div className="d-flex justify-content-center">
                        <h1 className="display-2">Sign Up</h1>
                    </div>
                </div>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <div style={{ textAlign: 'center' }}>
                                This service is only for people who own a{' '}
                                <strong>
                                    <i>@{emailDomain}</i>
                                </strong>
                                -mail. You will receive an email in order to
                                verify this.
                            </div>
                        </Grid>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        color="secondary"
                                        fullWidth
                                        id="signup-input-name"
                                        label="Name"
                                        name="name"
                                        autoComplete="name"
                                        onChange={(e) =>
                                            setName(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        color="secondary"
                                        id="signup-input-email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="password"
                                        color="secondary"
                                        label="Password"
                                        type="password"
                                        id="signup-input-password"
                                        autoComplete="current-password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="signup-btn-privacy"
                                                value="allowExtraEmails"
                                                color="secondary"
                                                onChange={() =>
                                                    handleReadPrivacy()
                                                }
                                            />
                                        }
                                        label={
                                            <>
                                                I've read the{' '}
                                                <Link
                                                    color="secondary"
                                                    href="/privacy"
                                                >
                                                    privacy notes
                                                </Link>
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                id="signup-btn-signup"
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={(e) => signUp(e)}
                            >
                                Sign Up
                            </Button>
                            <Grid container justify="flex-end">
                                <Grid item>
                                    <Link
                                        href="/"
                                        variant="body2"
                                        color="secondary"
                                    >
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Container>
            </article>
        )
    }
}
