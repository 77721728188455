import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Error, Loader } from '../../components/Utils'
import { addActivityHistory } from '../../utils/auth/thunks'
import './style.css'
import Chip from '@material-ui/core/Chip'
import { ActivityDatePicker } from './activityDatePicker'
import { green } from '@material-ui/core/colors'
import { AddActivityMenu } from './addActivityMenu'
import CircularProgress from '@material-ui/core/CircularProgress'
import { toast } from 'react-toastify'
import { Check, Warning } from '../../components/ToastContent'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { FaQuestionCircle } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            fullWidth: true,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
        fullWidth: true,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

export const AddActivity = () => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { categories, isLoading, fetchError } = useSelector(
        (state) => state.category
    )

    const { currentPeriod } = useSelector((state) => state.period.data)
    const { status } = useSelector((state) => state.user.config)
    const [open, setOpen] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState()
    const [duration, setDuration] = useState(0)
    const [date, setDate] = useState(new Date())

    const handleClickOpen = () => {
        setOpen(true)
    }

    const isFinished = () => {
        const today = new Date()
        const correctToday = new Date(
            today.setHours(today.getHours())
        ).toISOString()
        return correctToday >= currentPeriod.registrationEnd
    }

    const isInFreeze = () => {
        const today = new Date()
        const correctToday = new Date(
            today.setHours(today.getHours())
        ).toISOString()
        return correctToday <= currentPeriod.registrationStart
    }

    const isInactive = () => {
        if (status === 'Inactive') return true
        else return false
    }

    const isTooLate = () => {
        if (status === 'Inactive') {
            const today = new Date()
            const correctToday = new Date(
                today.setHours(today.getHours())
            ).toISOString()
            if (correctToday >= currentPeriod.freezeEnd) return true
        } else return false
    }

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const handleActivateUser = () => {
        alert('Active')
    }

    const handleClose = (action) => {
        var reg = new RegExp(/^\d+$/)

        if (action === 'add') {
            if (selectedActivity === undefined) {
                toast.warning(<Warning text="Please select an activity" />)
                return
            }

            if (duration.length === 0) {
                toast.warning(<Warning text="Please set a valid duration" />)
                return
            }

            if (!reg.test(duration)) {
                toast.warning(<Warning text="Duration can only be a number" />)
                return
            }

            dispatch(
                addActivityHistory(
                    selectedActivity,
                    duration,
                    date,
                    token,
                    currentPeriod.registrationStart,
                    currentPeriod.registrationEnd
                )
            ).then(() => {
                toast.success(<Check text="Activity added" />)
                if (
                    date < currentPeriod.registrationStart ||
                    date > currentPeriod.registrationEnd
                ) {
                    toast.warning(
                        <Warning text="Note that the activity you added is outside the current period." />
                    )
                }
            })
        }
        setOpen(false)
        setDuration(0)
    }

    const onActivityChange = (activity) => {
        setSelectedActivity(activity)
    }

    const onDurationChange = (event) => {
        setDuration(event.target.value)
    }

    const onDateChange = (date) => {
        setDate(date.toISOString())
    }

    const addTime = (time) => {
        setDuration(+duration + +time)
    }

    return (
        <>
            {!isLoading && fetchError && <Error />}
            {!currentPeriod && <Loader />}
            {!fetchError && categories && currentPeriod && (
                <>
                    <div className={classes.wrapper}>
                        <Button
                            disabled={
                                isInFreeze() ||
                                isTooLate() ||
                                isLoading ||
                                isFinished()
                            }
                            variant="outlined"
                            color="secondary"
                            onClick={
                                isInactive()
                                    ? handleActivateUser
                                    : handleClickOpen
                            }
                        >
                            {isFinished()
                                ? 'Period Finished'
                                : isInFreeze()
                                ? 'Closed'
                                : isInactive()
                                ? isTooLate()
                                    ? 'Closed'
                                    : 'Activate my user'
                                : 'Add Activity'}
                        </Button>
                        {isLoading && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                        {isTooLate() ? (
                            <Tooltip
                                disableFocusListener
                                title={`Unfortunately, you registered too late for this period. It lasts from ${new Date(
                                    currentPeriod.registrationStart
                                ).toLocaleDateString('en-US', {
                                    dateStyle: 'long',
                                })} until end of ${new Date(
                                    currentPeriod.registrationEnd
                                ).toLocaleDateString('en-US', {
                                    dateStyle: 'long',
                                })}`}
                                arrow
                            >
                                <IconButton
                                    className="no-outline"
                                    aria-label="delete"
                                >
                                    <FaQuestionCircle />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                        {isInFreeze() ? (
                            <Tooltip
                                disableFocusListener
                                title={`It is not possible to add activities during the user registration period. It lasts from ${new Date(
                                    currentPeriod.freezeStart
                                ).toLocaleDateString('en-US', {
                                    dateStyle: 'long',
                                })} until end of ${new Date(
                                    currentPeriod.freezeEnd
                                ).toLocaleDateString('en-US', {
                                    dateStyle: 'long',
                                })}`}
                                arrow
                            >
                                <IconButton
                                    className="no-outline"
                                    aria-label="delete"
                                >
                                    <FaQuestionCircle />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </div>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Add Activity
                        </DialogTitle>
                        <DialogContent>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <AddActivityMenu
                                                    unmountOnExit
                                                    onActivityChange={
                                                        onActivityChange
                                                    }
                                                    selectedActivity={
                                                        selectedActivity
                                                    }
                                                    categories={categories}
                                                />
                                            </div>
                                            <div className="row activity-sub-buttons">
                                                <Box
                                                    justifyContent="right"
                                                    width={1}
                                                ></Box>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <TextField
                                                    style={{
                                                        minWidth: 240,
                                                        marginRight: 20,
                                                        width: 170,
                                                    }}
                                                    id="duration"
                                                    label="Duration"
                                                    variant="outlined"
                                                    value={duration}
                                                    color="secondary"
                                                    onChange={onDurationChange}
                                                />
                                            </div>
                                            <div className="row activity-sub-buttons">
                                                <Box
                                                    display="flex"
                                                    justifyContent="right"
                                                    width={1}
                                                    pt={1}
                                                >
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+15min"
                                                            onClick={() =>
                                                                addTime(15)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+30min"
                                                            onClick={() =>
                                                                addTime(30)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+1hr"
                                                            onClick={() =>
                                                                addTime(60)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+2hrs"
                                                            onClick={() =>
                                                                addTime(120)
                                                            }
                                                        />
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                    <ActivityDatePicker
                                        onDateChange={onDateChange}
                                    />
                                </div>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose()}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleClose('add')}
                                color="secondary"
                                variant="outlined"
                            >
                                Add Activity
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    )
}
