import React from 'react'
import { PieChart, Pie, Cell } from 'recharts'

export const PlayPage = () => {
    const data = [
        { name: 'Group A', value: 400 },
        { name: 'Group B', value: 300 },
        { name: 'Group C', value: 300 },
        { name: 'Group D', value: 200 },
    ]
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

    const onPieEnter = (index) => {
        console.log('Entering ', index)
    }

    return (
        <PieChart width={100} height={110}>
            <Pie
                data={data}
                cx={40}
                cy={40}
                innerRadius={25}
                outerRadius={45}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
            >
                {data.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                        onMouseEnter={() => onPieEnter(index)}
                    />
                ))}
            </Pie>
        </PieChart>
    )
}
