import React, { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { SnackBarContext } from '../../components/SnackbarProvider'
import { resetPassword } from '../../utils/auth/thunks'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export const ResetPassword = () => {
    const classes = useStyles()
    const [email, setEmail] = useState('')
    const SnackBar = useContext(SnackBarContext)

    const dispatch = useDispatch()

    const onResetPassword = () => {
        dispatch(resetPassword(email))
            .then(() => {
                window.location.replace('/postresetpassword')
            })
            .catch((error) => {
                SnackBar.addAlert({
                    text: error,
                    severity: 'error',
                })
            })
    }

    return (
        <article>
            <div className="pt-5">
                <div className="d-flex justify-content-center">
                    <h1 className="display-3">Reset Password</h1>
                </div>
            </div>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                paddingBottom: '20px',
                            }}
                        >
                            We all forget things. Enter your email and you will
                            soon receive an email to reset your password.
                        </div>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                color="secondary"
                                variant="outlined"
                                required
                                fullWidth
                                id="forgotpassword-input-email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        id="btn-resetpassword"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={() => {
                            onResetPassword()
                        }}
                    >
                        Reset password
                    </Button>
                    <Grid container justify="flex-end">
                        <Grid item>
                            <Link href="/" variant="body2" color="secondary">
                                Go to login
                            </Link>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </article>
    )
}
