import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LandingPage } from '../pages/home/LandingPage'
import { ErrorPage } from '../pages/error/ErrorPage'
import { NotFoundPage } from '../pages/error/NotFoundPage'
import { PlayPage } from '../pages/play/PlayPage'
import './index.css'
import { SignUpPage } from '../pages/Landing/SignUpPage'
import { Page404 } from '../components/Utils'
import { Privacy } from '../pages/footer/privacy/privacy'
import 'fontsource-roboto'
import { Onboarding } from '../pages/intro/onboarding'
import { ResetPassword } from '../pages/Landing/ResetPassword'
import { PostResetPassword } from '../pages/Landing/PostResetPassword'
import { PostSignUp } from '../pages/Landing/PostSignUp'
import {HandleVerifyEmail} from '../pages/Landing/HandleVerifyEmail'

export const PublicLayout = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={LandingPage} />
                <Route exact path="/privacy" component={Privacy} />
                <Route path="/signup" component={SignUpPage} />
                <Route path="/verifyemail" component={PostSignUp} />
                <Route path="/emailverified" component={HandleVerifyEmail} />
                <Route path="/resetpassword" component={ResetPassword} />
                <Route
                    path="/postresetpassword"
                    component={PostResetPassword}
                />
                <Route path="/error" component={ErrorPage} />
                <Route path="/notfound" component={NotFoundPage} />
                <Route path="/play" component={PlayPage} />
                <Route path="/onboarding" component={Onboarding} />
                <Route path="/onboarding" component={Onboarding} />
                <Route component={Page404} />
            </Switch>
        </>
    )
}
