import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { removeActivityHistory } from '../../utils/auth/thunks'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import { EditActivity } from './editActivity'
import { toast } from 'react-toastify'
import './style.css'
import { Check } from '../../components/ToastContent'

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    customWidth: {
        maxWidth: 500,
    },
}))

export const MoreMenu = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const { currentPeriod } = useSelector((state) => state.period.data)
    const [menuElement, setMenuElement] = useState(null)
    const handleMenuClick = (event) => {
        setMenuElement(event.currentTarget)
    }
    const handleMenuClose = () => {
        setMenuElement(null)
    }

    const [deleteElement, setDeleteElement] = useState(null)
    const handleDeleteClick = (event) => {
        setDeleteElement(event.currentTarget)
    }
    const handleDeleteClose = () => {
        setDeleteElement(null)
        setMenuElement(null)
    }
    const handleDelete = (event) => {
        dispatch(
            removeActivityHistory(
                props.id,
                token,
                currentPeriod.registrationStart,
                currentPeriod.registrationEnd
            )
        )
        toast.success(<Check text="Activity deleted" />)
        handleDeleteClose()
    }

    const [editBoxOpen, setEditBoxOpen] = useState(false)
    const handleEditClick = (event) => {
        setEditBoxOpen(true)
        setMenuElement(null)
    }

    const open = Boolean(deleteElement)
    const id = open ? 'simple-popover' : undefined

    const isFinished = () => {
        const today = new Date()
        const correctToday = new Date(
            today.setHours(today.getHours() + 1)
        ).toISOString()
        return correctToday >= currentPeriod.registrationEnd
    }

    return (
        <div className="col col-1 edit-icons">
            <IconButton className="no-outline" size="small">
                <MoreVertIcon onClick={handleMenuClick} />
            </IconButton>

            <Menu
                id="simple-menu"
                anchorEl={menuElement}
                keepMounted
                open={Boolean(menuElement)}
                onClose={handleMenuClose}
            >
                <MenuItem disabled={isFinished()} onClick={handleEditClick}>
                    Edit
                </MenuItem>
                <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
            </Menu>

            <Popover
                id={id}
                open={open}
                anchorEl={deleteElement}
                onClose={handleDeleteClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.typography}>
                    <Box>Delete this activity?</Box>

                    <Button onClick={() => handleDeleteClose()}>Cancel</Button>
                    <Button
                        variant="outlined"
                        onClick={(e) => handleDelete(e)}
                        color="secondary"
                    >
                        Yes
                    </Button>
                </div>
            </Popover>

            <EditActivity
                id={props.id}
                activityId={props.activityId}
                activityName={props.activityName}
                date={props.date}
                duration={props.duration}
                openEdit={editBoxOpen}
                resetEditOpen={setEditBoxOpen}
            />
        </div>
    )
}
