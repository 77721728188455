import React from 'react'
import { useSelector } from 'react-redux'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

import 'react-circular-progressbar/dist/styles.css'
import './style.css'
import { MyActivitiesHistory } from './myActivitiesHistory'

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(8, 0, 6),
    },
    heroButtons: {
        marginTop: theme.spacing(4),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
        backgroundColor: theme.palette.primary.main,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
}))

export const MyActivities = () => {
    const { data } = useSelector((state) => state.activity)
    const classes = useStyles()

    if (data.days === undefined || data.days.length === 0) {
        return <></>
    }

    return (
        <>
            {data && (
                <>
                    <Card className={classes.card}>
                        <CardContent className={classes.cardContent}>
                            <MyActivitiesHistory />
                        </CardContent>
                    </Card>
                </>
            )}
        </>
    )
}
