import { createSlice } from '@reduxjs/toolkit'

const periodSlice = createSlice({
    name: 'period',
    initialState: {
        isLoading: false,
        fetchSuccess: false,
        fetchError: false,
        error: {},
        data: [{}],
    },
    reducers: {
        requestPeriodData(state) {
            state.isLoading = true
            state.fetchSuccess = false
            state.fetchError = false
        },
        successPeriodData(state, action) {
            state.isLoading = false
            state.fetchSuccess = true
            state.data = action.payload
        },
    },
})

export const { requestPeriodData, successPeriodData } = periodSlice.actions
export default periodSlice.reducer
