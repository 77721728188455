import React from 'react'
import { postError } from '../utils/api/api'
import WarningIcon from '@material-ui/icons/Warning'

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        postError(
            error.toString(),
            errorInfo.componentStack.toString(),
            this.props.token
        )
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{ fontSize: 'small', textAlign: 'center' }}>
                    <WarningIcon /> Something went wrong. We are working on it.
                </div>
            )
        }

        return this.props.children
    }
}
