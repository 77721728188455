import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { PeriodConfig } from './PeriodConfig'
import { UserConfig } from './UserConfig'
import { DataConfig } from './DataConfig'
import { useSelector } from 'react-redux'
import { TeamsConfig } from './TeamsConfig'
import { ActivityConfig } from './ActivityConfig'
import { AuditConfig } from './AuditConfig'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 80,
        flexGrow: 1,
        display: 'flex',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}))

export const Configuration = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    return (
        <div className={classes.root}>
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
            >
                <Tab label="Data" {...a11yProps(0)} />
                <Tab label="Periods" {...a11yProps(1)} />
                <Tab label="Users" {...a11yProps(2)} />
                <Tab label="Teams" {...a11yProps(3)} />
                <Tab label="Activities" {...a11yProps(4)} />
                <Tab label="Audit" {...a11yProps(5)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <ErrorBoundary token={token}>
                    <DataConfig />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ErrorBoundary token={token}>
                    <PeriodConfig />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ErrorBoundary token={token}>
                    <UserConfig />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <ErrorBoundary token={token}>
                    <TeamsConfig />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <ErrorBoundary token={token}>
                    <ActivityConfig />
                </ErrorBoundary>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <ErrorBoundary token={token}>
                    <AuditConfig />
                </ErrorBoundary>
            </TabPanel>
        </div>
    )
}
