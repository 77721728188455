import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'react-circular-progressbar/dist/styles.css'
import { Redirect } from 'react-router-dom'
import { exchangeCodeForTokenStrava } from '../../utils/api/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { fetchStravaTokenSuccess } from '../../utils/auth/authSlice'

export const StravaCallback = () => {
    const dispatch = useDispatch()
    const [returnToSettings, setReturnToSettings] = useState()

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const code = urlParams.get('code')

        exchangeCodeForTokenStrava(token, code).then((response) => {
            dispatch(fetchStravaTokenSuccess(response.data))
            setReturnToSettings(true)
        })
    }, [token, dispatch])

    if (returnToSettings) {
        return (
            <Redirect
                to={{
                    pathname: '/app/settings',
                }}
            />
        )
    } else
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <CircularProgress color="secondary" size={100} />
            </Grid>
        )
}
