import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import { useSelector } from 'react-redux'
import { submitOnboarding, getDepartments } from '../../utils/api/api'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import { toast } from 'react-toastify'
import { Warning } from '../../components/ToastContent'
import './style.css'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        backgroundColor: '#1a2744',
        border: '1px solid #2d4271',
        borderRadius: '8px',
        paddingTop: theme.spacing(3),
        padding: theme.spacing(2),
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    stepper: {
        position: 'absolute',
        bottom: '0',
        width: '100%',
        minWidth: '360px',
        right: '0',
        left: '0',
    },
    stepperButtons: {
        width: '100%',
        textAlign: 'center',
    },
    details: {
        alignItems: 'center',
    },
    columnMain: {
        flexBasis: '66.66%',
        border: '1px solid #04cc22',
        paddingTop: '17px',
        paddingBottom: '17px',
        borderRadius: '8px',
    },
    column: {
        flexBasis: '66.66%',
    },
    columnHelper: {
        flexBasis: '33.33%',
    },
    textField: {
        width: '100%',
        paddingRight: '14px',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 180,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        '&:focused': {
            backgroundColor: 'red',
        },
    },
}))

function getSteps() {
    return ['Welcome!', 'Basic Info', 'Set your level', "Let's go!"]
}

const countries = [
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
]

export const Onboarding = () => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = useState(0)
    const steps = getSteps()
    const [departmentId, setDepartmentId] = useState('')
    const [country, setCountry] = useState('')
    const [level, setLevel] = useState('')
    const handleChangeDepartmentId = (event) => {
        setDepartmentId(event.target.value)
    }
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const [departments, setDepartments] = useState()

    const handleLevelClick = (level) => {
        setLevel(level)
    }

    const handleChangeCountry = (event) => {
        setCountry(event.target.value)
    }

    const handleNext = () => {
        if (activeStep === 1) {
            if (departmentId.length === 0) {
                toast.warning(<Warning text="Please select a department" />)
            } else if (country.length === 0) {
                toast.warning(<Warning text="Please select a country" />)
            } else setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else if (activeStep === 2) {
            if (level.length === 0) {
                toast.warning(<Warning text="Please select a level" />)
            } else setActiveStep((prevActiveStep) => prevActiveStep + 1)
        } else setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    useEffect(() => {
        getDepartments(token).then((response) => {
            setDepartments(response)
        })
    }, [token])

    const displayName = useSelector((state) => state.auth.user.displayName)
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return (
                    <>
                        <Container className={classes.cardGrid} maxWidth="sm">
                            <Grid container spacing={4} justify="center">
                                <Grid item>
                                    <Box mb={0}>
                                        <h1>
                                            <i>Welcome!</i>
                                        </h1>
                                        <Divider variant="middle" />
                                    </Box>
                                </Grid>
                                <Grid>
                                    <Box mb={3}>
                                        We are so happy you would like to join
                                        us on this journey becoming{' '}
                                        <strong>Stronger Together</strong>!
                                    </Box>
                                </Grid>
                                <Grid>
                                    <Box mb={3}>
                                        Before we begin we like to make sure we
                                        have the correct information about you.
                                        Please follow these 4 simple steps to
                                        get started.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </>
                )
            case 1:
                return (
                    <>
                        <Container className={classes.cardGrid} maxWidth="sm">
                            <Grid container spacing={4} justify="center">
                                <Grid item md={12}>
                                    <Box mb={0}>
                                        <h2>
                                            <i>General Info</i>
                                        </h2>
                                        <Divider variant="middle" />
                                    </Box>
                                </Grid>
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box mb={3}>
                                        <div className={classes.column}>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="department-labelid">
                                                    Department
                                                </InputLabel>
                                                <Select
                                                    className={classes.select}
                                                    color="secondary"
                                                    style={{ width: 300 }}
                                                    labelId="department-labelid"
                                                    id="department"
                                                    value={departmentId}
                                                    onChange={
                                                        handleChangeDepartmentId
                                                    }
                                                    label="Department"
                                                >
                                                    <MenuItem value="">
                                                        <em>Select one</em>
                                                    </MenuItem>
                                                    {departments.map((item) => {
                                                        return (
                                                            <MenuItem
                                                                value={item.id}
                                                            >
                                                                {item.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Box>
                                    <Box mb={3}>
                                        <div className="classes.column">
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="country-labelid">
                                                    Country
                                                </InputLabel>
                                                <Select
                                                    color="secondary"
                                                    style={{ width: 300 }}
                                                    labelId="country-labelid"
                                                    id="country"
                                                    value={country}
                                                    onChange={
                                                        handleChangeCountry
                                                    }
                                                    label="Country"
                                                >
                                                    <MenuItem value="">
                                                        <em>Select one</em>
                                                    </MenuItem>

                                                    {countries.map(
                                                        (country) => {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        country.label
                                                                    }
                                                                >
                                                                    {
                                                                        country.label
                                                                    }
                                                                </MenuItem>
                                                            )
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </>
                )
            case 2:
                return (
                    <Container className={classes.cardGrid} maxWidth="sm">
                        <Grid container spacing={4} justify="center">
                            <Grid item>
                                <Box mb={0}>
                                    <h2>
                                        <i>Set Your Level</i>
                                    </h2>
                                    <Divider variant="middle" />
                                </Box>
                            </Grid>
                            <Grid item sm={12} md={12} lg={12}>
                                <Box mb={3}>
                                    <div
                                        className={`level-main green ${
                                            level === 'green' && 'green-active'
                                        }`}
                                        onClick={() =>
                                            handleLevelClick('green')
                                        }
                                    >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-6">
                                                    <h3>
                                                        <i>Eager</i>
                                                    </h3>
                                                </div>
                                                <div className="col level-helper">
                                                    Daily goal: 5000 steps
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                                <Box mb={3}>
                                    <div
                                        className={`level-main blue ${
                                            level === 'blue' && 'blue-active'
                                        }`}
                                        onClick={() => handleLevelClick('blue')}
                                    >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-6">
                                                    <h3>
                                                        <i>Sporty</i>
                                                    </h3>
                                                </div>
                                                <div className="col level-helper">
                                                    Daily goal: 10.000 steps
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                                <Box mb={3}>
                                    <div
                                        className={`level-main red ${
                                            level === 'red' && 'red-active'
                                        }`}
                                        onClick={() => handleLevelClick('red')}
                                    >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col col-6">
                                                    <h3>
                                                        <i>Active</i>
                                                    </h3>
                                                </div>
                                                <div className="col level-helper">
                                                    Daily goal: 15.000 steps
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                )
            case 3:
                return (
                    <>
                        <Container className={classes.cardGrid} maxWidth="md">
                            <Grid container spacing={4} justify="center">
                                <Grid item sm={12} md={12} lg={12}>
                                    <Box mb={0}>
                                        <h1>
                                            <i>You're all set!</i>
                                        </h1>
                                        <Divider variant="middle" />
                                    </Box>
                                </Grid>
                                <Grid>
                                    <Box mb={3}>
                                        Continue on to set your goals and start
                                        tracking your progress.
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </>
                )
            default:
                return 'Unknown stepIndex'
        }
    }

    if (activeStep === steps.length) {
        submitOnboarding(token, country, departmentId, level, true, displayName)
            .then((res) => {
                return window.location.replace('../app/home')
            })
            .catch((error) => {})
    } else {
        return (
            <>
                <div className={classes.root}>
                    <div>
                        <div style={{ paddingTop: '4vh' }}>
                            <Container maxWidth="md">
                                <Box
                                    className={classes.instructions}
                                    m={0}
                                    p={2}
                                >
                                    {getStepContent(activeStep)}
                                </Box>
                            </Container>
                        </div>
                    </div>
                    <div className={classes.stepper}>
                        <Container
                            maxWidth="lg"
                            className={classes.stepperButtons}
                        >
                            <Box justifyContent="flex-end" m={0} p={2}>
                                {activeStep === 0 ? (
                                    ''
                                ) : (
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </Button>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleNext}
                                >
                                    {activeStep === steps.length - 1
                                        ? 'Finish'
                                        : 'Next'}
                                </Button>
                            </Box>
                        </Container>
                        <Stepper
                            activeStep={activeStep}
                            maxWidth="lg"
                            alternativeLabel
                        >
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                </div>
            </>
        )
    }
}
