import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { PropertyBox } from '../PropertyBox'
import { useSelector } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}))

export const AccountSettings = () => {
    const classes = useStyles()
    const { config } = useSelector((state) => state.user)

    return (
        <Container maxWidth="md">
            <Box justifyContent="left" pl={1}>
                <Box justifyContent="left" m={0} pt={3}>
                    <Box>
                        <h2>Account Settings</h2>
                    </Box>
                </Box>

                <div className={classes.root}>
                    <PropertyBox value={config?.name} label={'Name'} />
                    <PropertyBox
                        value={config?.department?.name}
                        label={'Department'}
                    />
                    <PropertyBox value={config?.country} label={'Country'} />
                    <PropertyBox
                        value={config?.hasDoneOnboarding}
                        label={'Has done onboarding'}
                    />
                </div>
            </Box>
        </Container>
    )
}
