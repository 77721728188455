import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export const AssignTeamModal = (props) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (answer) => {
        if (answer === 'yes') {
            props.onAssignTeams()
        }
        setOpen(false)
    }

    return (
        <div>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={handleClickOpen}
                >
                    Assign teams
                </Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ⚠️ {'Warning'} ⚠️
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All users with status{' '}
                        <i>
                            <strong>
                                <div
                                    style={{
                                        color: '#FD6100',
                                        display: 'inline',
                                    }}
                                >
                                    active
                                </div>
                            </strong>
                        </i>{' '}
                        are given new teams. All users with status{' '}
                        <i>
                            <strong>
                                <div
                                    style={{
                                        color: '#FD6100',
                                        display: 'inline',
                                    }}
                                >
                                    inactive
                                </div>
                            </strong>
                        </i>{' '}
                        will not be given a team. This <i>cannot</i> be undone.
                        Are you sure this is what you want?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose('yes')}
                        color="secondary"
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button onClick={handleClose} color="white">
                        No, cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
