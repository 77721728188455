import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { removeActivityHistory } from '../../utils/auth/thunks'
import SettingsIcon from '@material-ui/icons/Settings'
import { Menu, MenuItem } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { toast } from 'react-toastify'
import NestedMenuItem from 'material-ui-nested-menu-item'
import { changeTheme } from '../../utils/auth/thunks'
import './style.css'
import { Check } from '../../components/ToastContent'

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    customWidth: {
        maxWidth: 500,
    },
}))

export const SettingsGraph = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const { currentPeriod } = useSelector((state) => state.period.data)
    const [menuElement, setMenuElement] = useState(null)
    const handleMenuClick = (event) => {
        setMenuPosition({
            top: event.pageY,
            left: event.pageX,
        })
        setMenuElement(event.currentTarget)
    }

    const [deleteElement, setDeleteElement] = useState(null)
    const handleDeleteClose = () => {
        setDeleteElement(null)
        setMenuElement(null)
    }
    const handleDelete = (event) => {
        dispatch(
            removeActivityHistory(
                props.id,
                token,
                currentPeriod.registrationStart,
                currentPeriod.registrationEnd
            )
        )
        toast.success(<Check text="Activity deleted" />)
        handleDeleteClose()
    }

    const open = Boolean(deleteElement)
    const id = open ? 'simple-popover' : undefined
    const [menuPosition, setMenuPosition] = useState(null)

    const handleItemClick = (theme) => {
        dispatch(changeTheme(token, props.area, theme))
        setMenuPosition(null)
    }

    return (
        <div className="col edit-icons">
            <IconButton
                className="no-outline graph-settings-button"
                size="small"
                style={{ opacity: props.show ? 1 : 0 }}
            >
                <SettingsIcon onClick={handleMenuClick} />
            </IconButton>

            <Menu
                open={!!menuPosition}
                onClose={() => setMenuPosition(null)}
                anchorReference="anchorPosition"
                anchorPosition={menuPosition}
            >
                <NestedMenuItem label="Theme" parentMenuOpen={!!menuPosition}>
                    <MenuItem onClick={() => handleItemClick('Fire')}>
                        Fire
                    </MenuItem>
                    <MenuItem onClick={() => handleItemClick('Forrest')}>
                        Forrest
                    </MenuItem>
                    <MenuItem onClick={() => handleItemClick('Ocean')}>
                        Ocean
                    </MenuItem>
                    <MenuItem onClick={() => handleItemClick('Breeze')}>
                        Breeze
                    </MenuItem>
                    <MenuItem onClick={() => handleItemClick('Mono')}>
                        Mono
                    </MenuItem>
                    <MenuItem onClick={() => handleItemClick('Valentine')}>
                        Valentine
                    </MenuItem>
                    <MenuItem onClick={() => handleItemClick('Skittles')}>
                        Skittles
                    </MenuItem>
                </NestedMenuItem>
            </Menu>
        </div>
    )
}
