import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'
import { getUsers } from '../../utils/api/api'
import { AuditConfigInstance } from './AuditConfigInstance'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { toast } from 'react-toastify'
import { Check, Error } from '../../components/ToastContent'
import { getAudits } from '../../utils/api/api'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        maxHeight: 400,
    },
}))

export const AuditConfig = () => {
    const classes = useStyles()

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const [audits, setAudits] = useState()

    useEffect(() => {
        getAudits(token).then((result) => {
            setAudits(result)
        })
    }, [token])

    return (
        <Container maxWidth="md">
            <Box justifyContent="left" pl={1}>
                <TableContainer component={Paper} className={classes.container}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Id</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Topic</TableCell>
                                <TableCell>Action</TableCell>
                                <TableCell>User</TableCell>
                                <TableCell>Details</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {audits === undefined ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                audits.map((audit) => (
                                    <AuditConfigInstance audit={audit} />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    )
}
