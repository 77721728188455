import React, { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import { UnitDisplayer } from '../../components/UnitDisplayer'
import { TitleDisplayer } from '../../components/TitleDisplayer'
import Grid from '@material-ui/core/Grid'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { ThemeProvider } from '../../components/ThemeProvider'
import { SettingsGraph } from '../home/SettingsGraph'
import { CompanyGraph } from './companyGraph'
import { UseStyles } from '../../styles/CommonStyles'
import { ViewMyCompany } from './viewMyCompany'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import './style.css'

export const MyCompany = memo(() => {
    const classes = UseStyles()
    const { companyGraphTheme } = useSelector((state) => state.user.config)
    const [colors, setColors] = useState(ThemeProvider(companyGraphTheme))
    const [showSettings, setShowSettings] = useState(0)
    const { data } = useSelector((state) => state.company)
    const { teamSummary } = { ...data }
    const { unit } = useSelector((state) => state.user.config)
    const [summary, setSummary] = useState()
    const [shortListSummary, setShortListSummary] = useState()
    const [active, setActive] = useState(false)

    const toggleSettings = (event) => {
        event.type === 'mouseenter' ? setShowSettings(1) : setShowSettings(0)
    }

    const toggleActive = () => {
        setActive(!active)
    }

    const useMountEffect = (fun) =>
        useEffect(fun, [unit, teamSummary, companyGraphTheme])

    const getShortList = (list) => {
        return list.slice(0, 7)
    }

    const renderGhostGraph = () => {
        return (
            <>
                <span>
                    <HelpOutlineIcon style={{ fontSize: '6.5rem' }} />
                </span>
                <span style={{ maxWidth: '108px' }}>
                    I wonder who's in the lead
                </span>
            </>
        )
    }

    const sortSummary = () => {
        setColors(ThemeProvider(companyGraphTheme))

        if (teamSummary !== undefined) {
            if (unit === 'Km' || unit === 'Steps' || unit === 'Points') {
                var sorted = [...teamSummary].sort((a, b) =>
                    a.totalStepsEquivalent > b.totalStepsEquivalent ? -1 : 1
                )
                setSummary(sorted)
                setShortListSummary(getShortList(sorted))
            } else if (unit === 'Time') {
                var timeSorted = [...teamSummary].sort((a, b) =>
                    a.totalDuration > b.totalDuration ? -1 : 1
                )
                setSummary(timeSorted)
                setShortListSummary(getShortList(timeSorted))
            }
        }
    }

    useMountEffect(sortSummary)

    if (!shortListSummary || shortListSummary.length === 0) {
        return (
            <div className="container" style={{ paddingTop: '75px' }}>
                <div className="row">
                    <div
                        className="col empty-you"
                        style={{ paddingTop: '7px' }}
                    >
                        <div
                            className="empty-team"
                            style={{
                                textAlign: 'center',
                                fontSize: 'small',
                                display: 'grid',
                                alignContent: 'center',
                            }}
                        >
                            {data.ghostDaysActive === true
                                ? renderGhostGraph()
                                : 'No activities registered in Allente yet'}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className={` ${active === true ? 'active' : ''}`}>
            <div
                className={classes.heroContent}
                onMouseEnter={(e) => toggleSettings(e)}
                onMouseLeave={(e) => toggleSettings(e)}
            >
                <div className="row">
                    <div
                        className="col col-12"
                        style={{
                            textAlign: 'center',
                            marginBottom: '15px',
                            fontSize: 'small',
                            color: '#9D9D9D',
                        }}
                    >
                        <strong>Allente</strong>
                        <br />
                        <i>
                            (Total <TitleDisplayer unit={unit} />:{' '}
                            <UnitDisplayer
                                unit={unit}
                                valueInTime={data?.companyTotalDuration}
                                valueInSteps={data?.companyTotalSteps}
                            />
                            )
                        </i>
                        <div
                            style={{
                                position: 'absolute',
                                marginBottom: '15px',
                                fontSize: 'small',
                                color: '#9D9D9D',
                                top: 0,
                                right: '30px',
                            }}
                        >
                            <SettingsGraph area="Company" show={showSettings} />
                            {/* <ViewMyCompany
                                area="Team"
                                show={showSettings}
                                toggleActive={toggleActive}
                            /> */}
                        </div>
                    </div>
                </div>

                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    {shortListSummary && (
                        <CompanyGraph
                            shortListSummary={shortListSummary}
                            colors={colors}
                            unit={unit}
                            data={data}
                        />
                    )}
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={10}>
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontSize: 'small',
                                        color: '#AAA',
                                    }}
                                >
                                    Showing top {shortListSummary.length} (of{' '}
                                    {teamSummary.length})
                                </div>
                            </div>
                            <div className="row summary-titles">
                                <div
                                    className="col col-1"
                                    style={{ padding: '0' }}
                                ></div>
                                <div className="col col-8">Team</div>
                                <div className="col col-3">
                                    <TitleDisplayer unit={unit} />
                                </div>
                            </div>
                        </div>

                        {shortListSummary?.map((entry, index) => {
                            if (entry.length === 0) {
                                return null
                            }
                            return (
                                <div className="container">
                                    <div className="row">
                                        <div
                                            className="col col-1"
                                            style={{
                                                padding: '0',
                                                color: colors[
                                                    index % colors.length
                                                ],
                                                bottom: '1px',
                                            }}
                                        >
                                            <FiberManualRecordIcon />
                                        </div>
                                        <div className="col col-8 activity-type-title">
                                            {entry.team?.name}
                                        </div>
                                        <div
                                            className="col col-3"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            <UnitDisplayer
                                                unit={unit}
                                                valueInTime={
                                                    entry.totalDuration
                                                }
                                                valueInSteps={
                                                    entry.totalStepsEquivalent
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Grid>
                </Grid>
            </div>
        </div>
    )
})
