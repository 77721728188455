import React, { useState } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ErrorPage } from '../pages/error/ErrorPage'
import { NotFoundPage } from '../pages/error/NotFoundPage'
import './index.css'
import { Page404 } from '../components/Utils'
import { createMuiTheme } from '@material-ui/core/styles'
import { pink, lightBlue, deepPurple } from '@material-ui/core/colors'
import 'fontsource-roboto'
import { Onboarding } from '../pages/intro/onboarding'
import { Selector } from '../pages/intro/Selector'

export const IntroLayout = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const isVerifying = useSelector((state) => state.auth.isVerifying)
    const [darkState, setDarkState] = useState(true)
    const palletType = darkState ? 'dark' : 'light'
    const mainPrimaryColor = darkState ? pink[500] : lightBlue[500]
    const mainSecondaryColor = darkState ? lightBlue[100] : deepPurple[500]
    const darkTheme = createMuiTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor,
            },
            secondary: {
                main: mainSecondaryColor,
            },
        },
    })
    const handleThemeChange = () => {
        setDarkState(!darkState)
    }

    const PrivateRoute = ({ component: Component, ...rest }) => {
        return (
            <Route
                {...rest}
                render={(props) =>
                    isVerifying ? (
                        <div />
                    ) : isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        )
    }

    return (
        <>
            <Switch checked={darkState} onChange={handleThemeChange} />
            <Switch>
                <PrivateRoute
                    exact
                    path="/intro/selector"
                    component={Selector}
                />
                <PrivateRoute
                    exact
                    path="/intro/onboarding"
                    component={Onboarding}
                />
                <Route path="error" component={ErrorPage} />
                <Route path="notfound" component={NotFoundPage} />
                <Route component={Page404} />
            </Switch>
        </>
    )
}
