import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSelector } from 'react-redux'
import Divider from '@material-ui/core/Divider'
import DateFnsUtils from '@date-io/date-fns'
import TextField from '@material-ui/core/TextField'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import AccordionActions from '@material-ui/core/AccordionActions'
import Button from '@material-ui/core/Button'
import { updatePeriod } from '../../utils/api/configurationApi'
import { toast } from 'react-toastify'
import { Check, Error } from '../../components/ToastContent'
import { FaInfoCircle } from 'react-icons/fa'
import { DateTimePicker } from '@material-ui/pickers'

import './style.css'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    summary: {
        backgroundColor: '#303030',
    },
    actions: {
        justifyContent: 'flex-start',
        paddingLeft: '12%',
        backgroundColor: '#303030',
    },
    details: {
        alignItems: 'center',
    },
    textField: {
        width: '100%',
        paddingRight: '14px',
    },
}))

export const PeriodConfigInstance = (props) => {
    const { period } = props
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const [freezeStart, setFreezeStart] = useState(period.freezeStart)
    const [freezeEnd, setFreezeEnd] = useState(period.freezeEnd)
    const [registrationStart, setRegistrationStart] = useState(
        period.registrationStart
    )
    const [registrationEnd, setRegistrationEnd] = useState(
        period.registrationEnd
    )
    const [ghostDays, setGhostDays] = useState(period.ghostDays)

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleChangeFreezeStart = (date) => {
        console.log(date)
        setFreezeStart(date)
    }

    const handleChangeFreezeEnd = (date) => {
        setFreezeEnd(date)
    }

    const handleChangeRegistrationStart = (date) => {
        setRegistrationStart(date)
    }

    const handleChangeRegistrationEnd = (date) => {
        setRegistrationEnd(date)
    }

    const handleChangeGhostDays = (event) => {
        setGhostDays(event.target.value)
    }

    const saveChanges = () => {
        updatePeriod(
            period.id,
            freezeStart,
            freezeEnd,
            registrationStart,
            registrationEnd,
            ghostDays,
            token
        )
            .then(() => {
                toast.success(<Check text="Period has been updated" />)
            })
            .catch(() => {
                toast.error(<Error text="Could not update period" />)
            })
    }
    return (
        <Accordion
            expanded={expanded === 'namePanel'}
            onChange={handleChange('namePanel')}
        >
            <AccordionSummary
                className={classes.summary}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
            >
                <Typography className={classes.heading}>
                    Period # {props.index}
                </Typography>
                <Typography className={classes.secondaryHeading}>
                    {new Date(freezeStart).toLocaleDateString('no-NO', {
                        dateStyle: 'short',
                    })}{' '}
                    -{' '}
                    {new Date(registrationEnd).toLocaleDateString('no-NO', {
                        dateStyle: 'short',
                    })}{' '}
                </Typography>
            </AccordionSummary>
            <Divider />
            <AccordionDetails className={classes.details}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="container period-date-box">
                                <div className="row">
                                    <div
                                        className="col"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div className="container">
                                            <div className="row">
                                                <div
                                                    className="col"
                                                    style={{
                                                        fontSize: 'larger',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <strong>
                                                        User registration
                                                    </strong>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div
                                                    className="col"
                                                    style={{
                                                        fontSize: 'smaller',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <FaInfoCircle
                                                        style={{
                                                            fontSize: 'x-large',
                                                            paddingRight: '5px',
                                                            color: '#FD6100',
                                                        }}
                                                    />
                                                    During this period it is
                                                    possible to register new
                                                    users, as well as activating
                                                    users.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-6 period-date period-date-left">
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <DateTimePicker
                                                autoOk
                                                ampm={false}
                                                color="secondary"
                                                value={freezeStart}
                                                onChange={(e) =>
                                                    handleChangeFreezeStart(e)
                                                }
                                                label="Start"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col col-6 period-date period-date-right">
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <DateTimePicker
                                                autoOk
                                                ampm={false}
                                                color="secondary"
                                                value={freezeEnd}
                                                onChange={(e) =>
                                                    handleChangeFreezeEnd(e)
                                                }
                                                label="End"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="container period-date-box">
                                <div className="row">
                                    <div
                                        className="col"
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <div className="container">
                                            <div className="row">
                                                <div
                                                    className="col"
                                                    style={{
                                                        fontSize: 'larger',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <strong>
                                                        Activity registration
                                                    </strong>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div
                                                    className="col"
                                                    style={{
                                                        fontSize: 'smaller',
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                    }}
                                                >
                                                    <FaInfoCircle
                                                        style={{
                                                            fontSize: 'x-large',
                                                            paddingRight: '5px',
                                                            color: '#FD6100',
                                                        }}
                                                    />
                                                    During this period it is
                                                    possible to register
                                                    activities. User
                                                    registration is closed.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col col-6 period-date period-date-left">
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <DateTimePicker
                                                autoOk
                                                ampm={false}
                                                color="secondary"
                                                value={registrationStart}
                                                onChange={(e) =>
                                                    handleChangeRegistrationStart(
                                                        e
                                                    )
                                                }
                                                label="Start"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="col col-6 period-date period-date-right">
                                        <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                        >
                                            <DateTimePicker
                                                autoOk
                                                ampm={false}
                                                color="secondary"
                                                value={registrationEnd}
                                                onChange={(e) =>
                                                    handleChangeRegistrationEnd(
                                                        e
                                                    )
                                                }
                                                label="End"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: '20px' }}>
                        <div className="col col-4">
                            <TextField
                                className={classes.textField}
                                id="name"
                                label="Ghost days"
                                variant="outlined"
                                color="secondary"
                                value={ghostDays}
                                onChange={handleChangeGhostDays}
                                size="small"
                            ></TextField>
                        </div>
                        <div className="col col-6">
                            <Typography variant="caption">
                                Number of days before the end where the results
                                are hidden
                            </Typography>
                        </div>
                    </div>
                </div>
            </AccordionDetails>
            <Divider />
            <AccordionActions className={classes.actions}>
                <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={() => saveChanges()}
                >
                    Save
                </Button>
            </AccordionActions>
        </Accordion>
    )
}
