import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import { FaInfoCircle } from 'react-icons/fa'

const useStyles = makeStyles({
    root: { border: '1px solid #FD6100', backgroundColor: '#1B2C36' },
})

export const Information = (props) => {
    const classes = useStyles()

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <CardContent>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        e
                        component="p"
                    >
                        <FaInfoCircle
                            style={{ fontSize: 'x-large', paddingRight: '5px' }}
                        />
                        {props.text}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}
