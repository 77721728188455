import React, { useState, useContext } from 'react'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { CircularProgress } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { loginUserEmail, loginUserGoogle } from '../../utils/auth/thunks'
import Box from '@material-ui/core/Box'
import { SnackBarContext } from '../../components/SnackbarProvider'
import Backdrop from '@material-ui/core/Backdrop'
import Divider from '@material-ui/core/Divider'
import './style.css'

export const LandingPage = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isLoading] = useState(false)
    const useStyles = makeStyles((theme) => ({
        paper: {
            marginTop: theme.spacing(8),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    }))

    const classes = useStyles()
    const dispatch = useDispatch()
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const isLoggingIn = useSelector((state) => state.auth.isLoggingIn)
    const SnackBar = useContext(SnackBarContext)

    var buildVersion = process.env.REACT_APP_BUILD_VERSION
    var env = process.env.REACT_APP_ENV

    const signIn = (e) => {
        e.preventDefault()
        dispatch(loginUserEmail(email, password)).then((response) => {
            if (response !== undefined) {
                if (response.code === 'auth/wrong-password') {
                    SnackBar.addAlert({
                        text: 'Incorrect email or password',
                        severity: 'error',
                    })
                }
                if (response.code === 'auth/user-not-found') {
                    SnackBar.addAlert({
                        text: response.message,
                        severity: 'error',
                    })
                }
            }
        })
    }

    const signInWithGoogle = (e) => {
        e.preventDefault()
        dispatch(loginUserGoogle()).then((response) => {
            SnackBar.addAlert({
                text: 'Incorrect email or password',
                severity: 'error',
            })
        })
    }

    const renderBackdrop = () => (
        <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
        </Backdrop>
    )

    if (isAuthenticated) {
        return (
            <Redirect
                to={{
                    pathname: 'intro/selector',
                }}
            />
        )
    }

    return (
        <>
            {isLoggingIn && renderBackdrop()}
            <article>
                <div className="pt-2">
                    <div className="d-flex justify-content-center">
                        <h1 className="display-3 landing-title-top">
                            <i>Stronger</i>
                        </h1>
                    </div>
                    <div className="d-flex justify-content-center">
                        <h1 className="display-3 landing-title-bottom">
                            <i>Together</i>
                        </h1>
                    </div>
                </div>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                color="secondary"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                color="secondary"
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => setPassword(e.target.value)}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value="remember"
                                        color="primary"
                                    />
                                }
                                label="Remember me"
                            />
                            <Button
                                id="landing-btn-signin-normal"
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={(e) => signIn(e)}
                                disabled={isLoading}
                            >
                                {isLoading && <CircularProgress size={24} />}

                                {!isLoading && 'Sign In'}
                            </Button>
                        </form>
                        <Box display="flex" justifyContent="center" m={0} p={0}>
                            <Box>
                                <Link
                                    id="landing-forgotpassword"
                                    href="/resetpassword"
                                    variant="body2"
                                    color="secondary"
                                >
                                    {'Forgot password?'}
                                </Link>
                            </Box>
                        </Box>
                        <Divider
                            variant="middle"
                            style={{
                                width: '100%',
                                marginTop: '15px',
                                marginBottom: '15px',
                            }}
                        />
                        <Box display="flex" justifyContent="center" m={0} p={0}>
                            <Box>
                                <Link id="landing-signup" href="/signup">
                                    <Button
                                        id="landing-btn-signin-normal"
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="secondary"
                                        className={classes.submit}
                                        disabled={isLoading}
                                    >
                                        Create New Account
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <Box pr={3} style={{ color: '#333' }}>
                                Version: {buildVersion}-{env}
                            </Box>

                            <Box>
                                {' '}
                                <Link
                                    id="landing-privacy"
                                    href="/privacy"
                                    variant="body2"
                                    color="secondary"
                                >
                                    {'Privacy'}
                                </Link>
                            </Box>
                        </Box>
                    </div>
                </Container>
            </article>
        </>
    )
}
