import { createSlice } from '@reduxjs/toolkit'

const auth = createSlice({
    name: 'auth',
    initialState: {
        isSigningUp: false,
        isLoggingIn: false,
        isLoggingOut: false,
        isVerifying: false,
        isAuthenticated: false,
        verifyError: false,
        loginError: false,
        logoutError: false,
        user: {},
        strava: {},
        fitbit: {},
    },

    reducers: {
        requestSignUp(state) {
            state.isSigningUp = true
        },
        requestLogin(state) {
            state.isLoggingIn = true
            state.loginError = false
        },
        loginCompleted(state) {
            state.isLoggingIn = false
        },
        successLogin(state, action) {
            state.isLoggingIn = false
            state.isAuthenticated = true
            var userData = JSON.parse(action.payload)
            state.user = userData
        },
        successSignUp(state, action) {
            state.isSigningUp = false
        },
        failureLogin(state) {
            state.isLoggingIn = false
            state.isAuthenticated = false
            state.loginError = true
        },
        requestLogout(state) {
            state.isLoggingOut = true
            state.logoutError = false
        },
        successLogout(state) {
            state.isLoggingOut = false
            state.isAuthenticated = false
            state.user = {}
        },
        failureLogout(state) {
            state.isLoggingOut = false
            state.logoutError = true
        },
        requestVerify(state) {
            state.isVerifying = true
            state.verifyError = false
        },
        successVerify(state) {
            state.isVerifying = false
        },
        fetchStravaTokenSuccess(state, action) {
            var accessToken = action.payload
            state.strava = accessToken
        },
        fetchFitbitTokenSuccess(state, action) {
            var accessToken = action.payload
            state.fitbit = accessToken
        },
    },
})

export const {
    requestLogin,
    successLogin,
    failureLogin,
    requestLogout,
    successLogout,
    failureLogout,
    requestVerify,
    successVerify,
    requestSignUp,
    successSignUp,
    loginCompleted,
    fetchStravaTokenSuccess,
    fetchFitbitTokenSuccess,
} = auth.actions

export default auth.reducer
