import { createSlice } from '@reduxjs/toolkit'

const companySlice = createSlice({
    name: 'company',
    initialState: {
        isLoading: false,
        fetchSuccess: false,
        fetchError: false,
        error: {},
        data: [{}],
    },
    reducers: {
        requestCompanyData(state) {
            state.isLoading = true
            state.fetchSuccess = false
            state.fetchError = false
        },
        successCompanyData(state, action) {
            state.isLoading = false
            state.fetchSuccess = true
            state.data = action.payload
        },
    },
})

export const { requestCompanyData, successCompanyData } = companySlice.actions
export default companySlice.reducer
