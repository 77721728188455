import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import { fetchUserConfig } from '../../utils/auth/thunks'
import Backdrop from '@material-ui/core/Backdrop'
import { setCurrentUserActive, getPeriodData } from '../../utils/api/api'
import './style.css'

export const ActivateUser = (props) => {
    const dispatch = useDispatch()
    const [open, setOpen] = useState(props.open)
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const [freezeEnd, setFreezeEnd] = useState()

    const handleClose = (answer) => {
        if (answer === 'yes') {
            setCurrentUserActive(token).then((res) => {
                dispatch(fetchUserConfig(token))
            })
        } else if (answer === 'ok') {
            dispatch(fetchUserConfig(token))
        }
        setOpen(false)
    }

    const renderActivateOrNot = () => {
        if (freezeEnd !== undefined) {
            if (new Date().toISOString() > freezeEnd) {
                return (
                    <>
                        Unfortunately, you are too late to join this period. But
                        don't despair, another period will start sooner than you
                        think!
                        <br />
                        <div style={{ padding: '15px' }}>
                            <center>
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleClose('ok')}
                                >
                                    Ok
                                </Button>
                            </center>
                        </div>
                    </>
                )
            } else {
                return (
                    <>
                        In order to join the next period you need to activate
                        your user. All you have to do is click the <i>yes</i>{' '}
                        button below.
                        <br />
                        <div style={{ padding: '15px' }}>
                            <center>
                                <Button
                                    style={{ marginBottom: '15px' }}
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => handleClose('yes')}
                                >
                                    Yes, activate my user
                                </Button>
                                <Button
                                    style={{
                                        marginLeft: '15px',
                                        marginBottom: '15px',
                                    }}
                                    variant="outlined"
                                    onClick={() => handleClose()}
                                >
                                    No, thanks
                                </Button>
                            </center>
                        </div>
                    </>
                )
            }
        }
    }

    useEffect(() => {
        getPeriodData(token).then((result) => {
            var { freezeEnd } = result.currentPeriod
            setFreezeEnd(freezeEnd)
        })
    }, [token])

    return (
        <>
            <Backdrop open={true}></Backdrop>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
                style={{ border: '1px solid #999' }}
            >
                <DialogTitle id="form-dialog-title">
                    <i>Hi there</i>{' '}
                    <span role="img" aria-label="hi">
                        👋
                    </span>
                </DialogTitle>
                <DialogContent>
                    Your user currently have the status as{' '}
                    <i>
                        <strong>
                            <div
                                style={{ color: '#FD6100', display: 'inline' }}
                            >
                                inactive
                            </div>
                        </strong>
                    </i>
                    . {renderActivateOrNot()}
                </DialogContent>
            </Dialog>
        </>
    )
}
