const Fire = [
    '#FCD116',
    '#FFB900',
    '#FF8C00',
    '#DD5900',
    '#EB3C00',
    '#E81123',
    '#BA141A',
]

const Skittles = [
    '#FFBB28',
    '#f008ff',
    '#08ffdd',
    '#00C49F',
    '#FF8042',
    '#0088FE',
    '#50ff08',
]

const Forrest = [
    '#00B294',
    '#008272',
    '#007233',
    '#009E49',
    '#55D455',
    '#7FBA00',
    '#BAD80A',
]

const Ocean = [
    '#2F4E94',
    '#3B62BA',
    '#4776E0',
    '#507AFF',
    '#6C50FF',
    '#8350FF',
    '#9A50FF',
    '#B150FF',
]

const Breeze = [
    '#6A8595',
    '#85A7BB',
    '#A0C9E1',
    '#B5DEFF',
    '#B5D4FF',
    '#B5CAFF',
    '#B5C0FF',
    '#B5B6FF',
    '#BDB5FF',
    '#C6B5FF',
    '#CFB5FF',
]

const Mono = [
    '#000000',
    '#262626',
    '#4C4C4C',
    '#727272',
    '#989898',
    '#BEBEBE',
    '#E4E4E4',
    '#FFFFFF',
]

const Valentine = [
    '#350F24',
    '#5B193D',
    '#812356',
    '#A72D6F',
    '#CD3788',
    '#F341A1',
    '#FF449C',
    '#FF438F',
    '#FF4282',
    '#FF4175',
    '#FF415B',
    '#FF4141',
]

export const ThemeProvider = (theme) => {
    switch (theme) {
        case 'Fire': {
            return Fire
        }
        case 'Forrest': {
            return Forrest
        }
        case 'Ocean': {
            return Ocean
        }
        case 'Breeze': {
            return Breeze
        }
        case 'Mono': {
            return Mono
        }
        case 'Valentine': {
            return Valentine
        }
        default: {
            return Skittles
        }
    }
}
