import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import ErrorIcon from '@material-ui/icons/Error'
import Tooltip from '@material-ui/core/Tooltip'
import { updateActivity } from '../../utils/api/configurationApi'
import { toast } from 'react-toastify'
import './style.css'
import { Check, Error } from '../../components/ToastContent'

const useStyles = makeStyles((theme) => ({
    smallTableCell: {
        minWidth: '100px',
    },
    mediumTableCell: {
        minWidth: '150px',
    },
    largeTableCell: {
        minWidth: '250px',
    },
}))

export const ActivityConfigInstance = (props) => {
    const classes = useStyles()
    const [enabled, setEnabled] = useState(props.row.enabled)
    const [id] = useState(props.row.id)
    const [name, setName] = useState(props.row.name)
    const [stepsPerMinute, setStepsPerMinute] = useState(
        props.row.stepsPerMinute
    )
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const [externalName, setExternalName] = useState(props.row.externalName)
    const [externalSource, setExternalSource] = useState(
        props.row.externalSource
    )

    const [dirty, setDirty] = useState(false)

    const handleClickEnable = (e) => {
        setEnabled(!enabled)
    }

    const toggleDirty = (e, item) => {
        if (e.target.value !== item) setDirty(true)
        else setDirty(false)
    }

    const handleChangeName = (e) => {
        toggleDirty(e, props.row.name)
        setName(e.target.value)
    }

    const handleChangeStepsPerMinute = (e) => {
        toggleDirty(e, props.row.stepsPerMinute)
        setStepsPerMinute(e.target.value)
    }

    const handleChangeGarminName = (e) => {
        toggleDirty(e, props.row.garminName)
        setExternalName(e.target.value)
    }

    const handleSave = () => {
        updateActivity(id, name, externalName, enabled, stepsPerMinute, token)
            .then(() => {
                toast.success(<Check text="Activity updated" />)
            })
            .catch((error) => {
                toast.error(<Error text="Could not update activity" />)
            })
    }

    return (
        <>
            <TableRow hover>
                <TableCell style={{ minWidth: '40px', maxWidth: '40px' }}>
                    {dirty === true ? (
                        <Tooltip title="Value has changed" arrow>
                            <ErrorIcon />
                        </Tooltip>
                    ) : (
                        ''
                    )}
                </TableCell>
                <TableCell padding="checkbox">
                    <Checkbox
                        checked={enabled}
                        onClick={(e) => handleClickEnable(e)}
                    />
                </TableCell>
                <TableCell align="left" className={classes.largeTableCell}>
                    <TextField
                        className={classes.textField}
                        id="name"
                        variant="outlined"
                        value={name}
                        size="small"
                        onChange={(e) => handleChangeName(e)}
                    ></TextField>
                </TableCell>
                <TableCell align="left" className={classes.smallTableCell}>
                    <TextField
                        className={classes.textField}
                        id="stepsPerMinute"
                        variant="outlined"
                        value={stepsPerMinute}
                        size="small"
                        onChange={(e) => handleChangeStepsPerMinute(e)}
                    ></TextField>
                </TableCell>
                <TableCell align="left" className={classes.mediumTableCell}>
                    <TextField
                        className={classes.textField}
                        id="gaminame"
                        variant="outlined"
                        placeholder="-"
                        value={externalSource === 'NULL' ? '' : externalSource}
                        size="small"
                    ></TextField>
                </TableCell>
                <TableCell align="left">
                    <IconButton aria-label="save" component="span">
                        <SaveIcon onClick={() => handleSave()} />
                    </IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}
