import React from 'react'
import './style.css'

export const Loader = () => (
    <div className="util-box">
        <div>
            <i className="fas fa-spinner fa-spin fa-3x" />
        </div>
    </div>
)

export const Error = () => (
    <div className="util-box">
        <div>
            <i className="fas fa-exclamation-triangle fa-8x"></i>
        </div>
        <div className="error-text">Hm, looks like I messed up here...</div>
    </div>
)

export const Page404 = () => (
    <div className="util-box">
        <div>
            <i className="fas fa-4x fa-times"></i>
        </div>
        <div className="error-text">
            The page you are looking for does not exist
        </div>
    </div>
)
