import React, { useState, useEffect } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import Button from '@material-ui/core/Button'
import { useSelector, useDispatch } from 'react-redux'
import { toggleFavorite } from '../../utils/auth/thunks'

const ITEM_HEIGHT = 48

export const AddActivityMenu = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('Select one')
    const [categories, setCategories] = useState(props.categories)
    const dispatch = useDispatch()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
        setOpen(true)
    }

    const handleClose = (event) => {
        setOpen(false)
    }

    const handlePick = (id, name) => {
        props.onActivityChange(id)
        setValue(name)
        setOpen(false)
    }

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const switchFavorite = (id) => {
        dispatch(toggleFavorite(token, id))
        var updatedCategories = categories.map((item) => {
            return item.id === id
                ? { ...item, isFavorite: !item.isFavorite }
                : item
        })
        setCategories(updatedCategories)
    }

    const renderStar = (isFavorite) => {
        if (isFavorite) return <StarIcon />
        else return <StarBorderIcon />
    }

    const useMountEffect = (fun) => useEffect(fun, [])

    const sortCategories = () => {
        setCategories(
            [...categories].sort((a, b) =>
                a.isFavorite > b.isFavorite ? -1 : 1
            )
        )

        setValue(props.activityName)
        props.onActivityChange(props.activityId)
    }

    useMountEffect(sortCategories)

    return (
        <div>
            <TextField
                InputProps={{
                    readOnly: true,
                }}
                style={{
                    minWidth: 240,
                    marginRight: 20,
                    width: 170,
                }}
                id="activity"
                label="Activity"
                variant="outlined"
                color="secondary"
                value={value}
                onClick={handleClick}
            />
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 6.5,
                        width: '30ch',
                    },
                }}
            >
                {categories.map((option, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handlePick(option.id, option.name)}
                    >
                        <ListItemText key={option} primary={option.name} />
                        <ListItemIcon>
                            <Button
                                className="star-button"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()
                                    switchFavorite(option.id)
                                }}
                                color="secondary"
                            >
                                {renderStar(option.isFavorite)}
                            </Button>
                        </ListItemIcon>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}
