import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'
import { getTeams } from '../../utils/api/api'
import { TeamConfigInstance } from './TeamConfigInstance'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        maxHeight: 500,
    },
}))

export const TeamsConfig = () => {
    const classes = useStyles()

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const [teamsData, setTeamsData] = useState()

    useEffect(() => {
        getTeams(token).then((result) => {
            setTeamsData(result)
        })
    }, [token])

    return (
        <Container maxWidth="md">
            <Box justifyContent="left" pl={1}>
                <Box justifyContent="left" m={0} pt={3}>
                    <Box>
                        <h2>
                            Teams{' '}
                            {teamsData?.teams?.length &&
                                `(${teamsData?.teams?.length})`}
                        </h2>
                        <div>
                            With users:{' '}
                            <strong>{teamsData?.numberTeamsWithUsers}</strong>
                        </div>
                        <div>
                            Without users:{' '}
                            <strong>
                                {teamsData?.teams?.length -
                                    teamsData?.numberTeamsWithUsers}
                            </strong>
                        </div>
                    </Box>
                </Box>
                <TableContainer component={Paper} className={classes.container}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Name</TableCell>
                                <TableCell>Number of members</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {teamsData?.teams === undefined ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                teamsData?.teams.map((row) => (
                                    <TeamConfigInstance
                                        key={row.name}
                                        row={row}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    )
}
