import { createSlice } from '@reduxjs/toolkit'

const teamSlice = createSlice({
    name: 'team',
    initialState: {
        isLoading: false,
        fetchSuccess: false,
        teamFetchSuccess: false,
        fetchError: false,
        error: {},
        data: [{}],
    },
    reducers: {
        assigningTeam(state) {
            state.isLoading = true
            state.fetchSuccess = false
            state.fetchError = false
        },
        assignedToTeam(state) {
            state.isLoading = false
            state.fetchSuccess = true
            state.fetchError = false
        },
        requestTeams(state) {
            state.isLoading = true
            state.fetchSuccess = false
            state.fetchError = false
        },
        requestTeamsSuccess(state, action) {
            state.isLoading = false
            state.fetchSuccess = true
            state.data = action.payload
        },
        requestTeamDataSuccess(state, action) {
            state.isLoading = false
            state.teamFetchSuccess = true
            state.data = action.payload
        },
    },
})

export const {
    requestTeams,
    requestTeamsSuccess,
    assignedToTeam,
    assigningTeam,
    requestTeamDataSuccess,
    teamFetchSuccess,
} = teamSlice.actions
export default teamSlice.reducer
