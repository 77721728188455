import React, { Component, createContext } from 'react'

export const UserContext = createContext({ user: null })

class UserProvider extends Component {
    state = {
        user: null,
    }

    render() {
        return (
            <UserContext.Provider value={this.state.user}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}
export default UserProvider
