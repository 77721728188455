import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import IconButton from '@material-ui/core/IconButton'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export const DeleteUserModal = (props) => {
    const [open, setOpen] = React.useState(false)
    const [deleteUserData, setDeleteUserData] = useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (answer) => {
        if (answer === 'yes') {
            debugger
            props.handleDeleteUser(props.userId, deleteUserData.toString())
        }
        setOpen(false)
    }

    const handleDeleteUserDataCheckbox = () => {
        setDeleteUserData(!deleteUserData)
    }

    return (
        <div>
            <div
                style={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingRight: '10px',
                }}
            >
                <IconButton aria-label="save" component="span">
                    <DeleteForeverIcon onClick={() => handleClickOpen()} />
                </IconButton>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span role="img" aria-label="Warning">
                        ⚠️
                    </span>{' '}
                    {'Delete user?'}{' '}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <strong>This is serious!</strong> This will delete the
                        user. This <i>cannot</i> be undone. Are you sure this is
                        what you want?
                    </DialogContentText>
                    <FormControlLabel
                        control={
                            <Checkbox
                                value="remember"
                                color="secondary"
                                onChange={() => handleDeleteUserDataCheckbox()}
                            />
                        }
                        label="Also delete all activitiy data related to this user"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose('yes')}
                        color="secondary"
                        variant="outlined"
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button onClick={handleClose} color="white">
                        No, cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
