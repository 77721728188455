import { makeStyles } from '@material-ui/core/styles'

export const UseStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: '#0b1222',
        padding: theme.spacing(5, 0, 0),
    },
}))
