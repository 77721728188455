import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { myFirebase } from '../../utils/auth/Firebase'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../utils/auth/thunks'
import { toast } from 'react-toastify'
import { Check } from '../../components/ToastContent'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export const PostSignUp = () => {
    const classes = useStyles()

    const { emailVerified } = useSelector((state) => state.auth.user)
    const dispatch = useDispatch()

    const resendEmail = () => {
        myFirebase
            .auth()
            .currentUser.sendEmailVerification()
            .then(() => {
                toast.success(<Check text="Email sent" />)
            })
            .catch((error) => {
                console.error(error)
            })
    }

    const signOutAndGoToLanding = () => {
        dispatch(logoutUser())
            .then(() => {
                return (
                    <Redirect
                        to={{
                            pathname: '/',
                        }}
                    />
                )
            })
            .catch((error) => {
                console.log('Could not log out')
            })
    }

    if (emailVerified) {
        return (
            <Redirect
                to={{
                    pathname: '/',
                }}
            />
        )
    }

    const returnToHome = () => {
        return (
            <Redirect
                to={{
                    pathname: '/',
                }}
            />
        )
    }

    return (
        <article>
            <div className="pt-5">
                <div className="d-flex justify-content-center">
                    <h2 className="display-3">Verify your email</h2>
                </div>
            </div>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <div style={{ textAlign: 'center' }}>
                            You should receive an email shortly explaining how
                            to verify your email.
                        </div>
                    </Grid>
                    <a href="/">
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="secondary"
                            className={classes.submit}
                            style={{ marginBottom: '0' }}
                            onClick={(e) => returnToHome(e)}
                        >
                            I've verified my mail - Let's go!
                        </Button>
                    </a>
                    <Button
                        style={{ color: 'grey' }}
                        size="small"
                        onClick={(e) => resendEmail(e)}
                    >
                        Did not get the mail? Resend email
                    </Button>
                    <a href="/">
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => signOutAndGoToLanding()}
                        >
                            Take me to the login page
                        </Button>
                    </a>
                </div>
            </Container>
        </article>
    )
}
