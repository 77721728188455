import React, { useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import { SnackBarContext } from '../../components/SnackbarProvider'
import FeedbackIcon from '@material-ui/icons/Feedback'
import { postFeedback } from '../../utils/api/api'
import { toast } from 'react-toastify'
import { Check, Warning } from '../../components/ToastContent'
import { StepContent } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            fullWidth: true,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
        fullWidth: true,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

export const GiveFeedBack = () => {
    const classes = useStyles()

    const SnackBar = useContext(SnackBarContext)
    const [open, setOpen] = useState(false)
    const [text, setText] = useState('')
    const [title, setTitle] = useState('')
    const [topic, setTopic] = useState('')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const handleTextChange = (event) => {
        setText(event.target.value)
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value)
    }

    const handleClose = (action) => {
        if (action === 'add') {
            if (text.length === 0) {
                toast.warning(<Warning text="Please state the feedback" />)
                return
            }

            if (title.length === 0) {
                toast.warning(<Warning text="Please add a title" />)
                return
            }

            postFeedback(title, text, token).then(() => {
                setText('')
                setTitle('')
                toast.success(<Check text="Thank you for your feedback" />)
            })
        }
        setOpen(false)
    }

    return (
        <>
            <>
                <div className="row">
                    <div className="col profile-name-box profile-box-center">
                        <Button
                            className={classes.buttons}
                            size="large"
                            startIcon={<FeedbackIcon />}
                            onClick={(e) => handleClickOpen(e)}
                        >
                            Give Feedback
                        </Button>
                    </div>
                </div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">
                        Give Feedback
                    </DialogTitle>
                    <DialogContent>
                        <FormControl
                            variant="outlined"
                            className={classes.formControl}
                        >
                            <div className="container">
                                <div className="row">
                                    <div className="container">
                                        <div className="row">
                                            <TextField
                                                style={{
                                                    minWidth: 240,
                                                    marginRight: 20,
                                                    width: 170,
                                                }}
                                                id="title"
                                                label="Title"
                                                variant="outlined"
                                                value={title}
                                                color="secondary"
                                                onChange={handleTitleChange}
                                            />
                                        </div>
                                        <div className="row">
                                            <TextField
                                                style={{
                                                    marginTop: 20,
                                                    minWidth: 240,
                                                    marginRight: 20,
                                                    width: 170,
                                                }}
                                                multiline
                                                rows={6}
                                                id="text"
                                                label="Text"
                                                variant="outlined"
                                                value={text}
                                                color="secondary"
                                                onChange={handleTextChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleClose()}>Cancel</Button>
                        <Button
                            onClick={() => handleClose('add')}
                            color="secondary"
                            variant="outlined"
                        >
                            Send Feedback
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        </>
    )
}
