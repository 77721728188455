import { createSlice } from '@reduxjs/toolkit'

const categorySlice = createSlice({
    name: 'category',
    initialState: {
        isLoading: false,
        fetchSuccess: false,
        fetchError: false,
        error: {},
        categories: [{}],
    },

    reducers: {
        requestCategories(state) {
            state.isLoading = true
            state.fetchError = false
        },
        categoriesFetched(state, action) {
            state.isLoading = false
            state.fetchError = false
            state.fetchSuccess = true
            state.categories = JSON.parse(action.payload)
        },
    },
})

export const { requestCategories, categoriesFetched } = categorySlice.actions

export default categorySlice.reducer
