import React from 'react'
import { useSelector } from 'react-redux'
import PeopleIcon from '@material-ui/icons/People'
import IconButton from '@material-ui/core/IconButton'
import './style.css'
import Popover from '@material-ui/core/Popover'

export const ViewMyTeam = (props) => {
    const { team, teamName } = useSelector((state) => state.team?.data)
    const handleMenuClick = (event) => {}
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const renderTeamName = () => {
        if (teamName !== undefined) {
            return <div style={{ fontWeight: '700' }}>{teamName}</div>
        } else return 'Not assigned a team yet'
    }

    return (
        <div className="col" style={{ padding: '0' }}>
            <IconButton
                onClick={handleClick}
                className="no-outline graph-settings-button"
                style={{ opacity: props.show ? 1 : 0 }}
            >
                <PeopleIcon onClick={handleMenuClick} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="container" style={{ width: '250px' }}>
                    <div className="row">
                        <div
                            className="col"
                            style={{
                                padding: '10px',
                                textAlign: 'center',
                                borderBottom: '1px solid #8888',
                            }}
                        >
                            {renderTeamName()}
                        </div>
                    </div>
                    {team?.map((user) => {
                        return (
                            <div className="row">
                                <div
                                    className="col"
                                    style={{
                                        paddingLeft: '18px',
                                        paddingBottom: '5px',
                                    }}
                                >
                                    {user.name}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Popover>
        </div>
    )
}
