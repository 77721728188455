import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { DeleteUserModal } from './DeleteUserModal'
import Switch from '@material-ui/core/Switch'
import { setUserInactive, setUserActive } from '../../utils/api/api'

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
})

export const UserConfigInstance = (props) => {
    const classes = useRowStyles()
    const [userStatus, setUserStatus] = useState()
    const { user } = props
    const [open, setOpen] = React.useState(false)
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const handleChecked = () => {
        if (userStatus === 'Active') {
            setUserStatus('Inactive')
            setUserInactive(user.id, token)
        } else {
            setUserStatus('Active')
            setUserActive(user.id, token)
        }
    }

    useEffect(() => {
        setUserStatus(props.user.status)
    }, [props.user.status])

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {user.name}
                </TableCell>
                <TableCell component="th" scope="row">
                    {user.email}
                </TableCell>
                <TableCell>
                    <Switch
                        checked={userStatus === 'Active' ? true : false}
                        onChange={handleChecked}
                        color="secondary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    {user.status || '-'}
                </TableCell>
                <TableCell>{user.team?.name || '-'}</TableCell>
                <TableCell>
                    <DeleteUserModal
                        handleDeleteUser={props.handleDeleteUser}
                        status="active"
                        userId={user.id}
                    />
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                component="div"
                            >
                                Info
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Country</TableCell>
                                        <TableCell>Department</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Total Steps</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {user === undefined ? (
                                        <TableRow>
                                            <TableCell align="right">
                                                No data
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        <TableRow key={user.uuid}>
                                            <TableCell>{user.name}</TableCell>
                                            <TableCell>
                                                {user.country}
                                            </TableCell>
                                            <TableCell>
                                                {user.department?.name ?? '-'}
                                            </TableCell>
                                            <TableCell>{user.email}</TableCell>
                                            <TableCell>
                                                {user.totalNumberOfSteps}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
