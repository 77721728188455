import React from 'react'
import { Icon } from '@iconify/react'
import badmintonIcon from '@iconify-icons/mdi/badminton'
import shoeBallet from '@iconify-icons/mdi/shoe-ballet'
import baseballBat from '@iconify-icons/mdi/baseball-bat'
import billiardsIcon from '@iconify-icons/mdi/billiards'
import foodTurkey from '@iconify-icons/mdi/food-turkey'
import danceBallroom from '@iconify-icons/mdi/dance-ballroom'

import {
    FaRunning,
    FaBowlingBall,
    FaBiking,
    FaBasketballBall,
    FaWalking,
    FaHiking,
    FaGamepad,
    FaChild,
    FaWrench,
    FaHorse,
    FaSkating,
    FaSkiingNordic,
    FaSwimmer,
    FaVolleyballBall,
    FaSkiing,
    FaWeightHanging,
    FaMountain,
    FaSnowboarding,
} from 'react-icons/fa'

import {
    GiFlowerPot,
    GiJumpingRope,
    GiRollerSkate,
    GiBoxingGlove,
    GiFencer,
    GiCampfire,
    GiBoatFishing,
    GiFrisbee,
    GiGolfTee,
    GiHockey,
    GiHorseshoe,
    GiVacuumCleaner,
    GiCanoe,
    GiGrass,
    GiPaintRoller,
    GiSailboat,
    GiScubaMask,
    GiStairs,
    GiWaveSurfer,
    GiTennisBall,
    GiWeightLiftingUp,
    GiKite,
    GiSkateboard,
} from 'react-icons/gi'

import { FiShoppingCart } from 'react-icons/fi'

import { MdRowing } from 'react-icons/md'

import { CgGym } from 'react-icons/cg'

import { BiFootball } from 'react-icons/bi'

import { GiTennisRacket } from 'react-icons/gi'

export const RenderActivityIcon = (props) => {
    switch (props.activity) {
        case 'Running': {
            return <FaRunning />
        }
        case 'Backcountry Ski':
        case 'Alpine Ski': {
            return <FaSkiing />
        }
        case 'Badminton, casual':
        case 'Badminton, competitive': {
            return <Icon icon={badmintonIcon} />
        }
        case 'Ballet dancing': {
            return <Icon icon={shoeBallet} />
        }
        case 'Baseball': {
            return <Icon icon={baseballBat} />
        }
        case 'Billiards/pool': {
            return <Icon icon={billiardsIcon} />
        }
        case 'Bowling': {
            return <FaBowlingBall />
        }
        case 'Bowling on the Wii': {
            return <FaGamepad />
        }
        case 'Boxing, competitive':
        case 'Boxing, non-competitive': {
            return <GiBoxingGlove />
        }
        case 'Bike':
        case 'E-Bike ride':
        case 'CYCLING':
        case 'Cycling':
        case 'Ride':
        case 'Spinning':
        case 'Bicycling, easy pace':
        case 'Bicycling, moderate pace':
        case 'Bicycling, vigorous pace':
        case 'Biking': {
            return <FaBiking />
        }
        case 'Basketball, recreational':
        case 'Basketball, game':
        case 'Basketball': {
            return <FaBasketballBall />
        }
        case 'Childrens playground game': {
            return <FaChild />
        }
        case 'Cooking': {
            return <Icon icon={foodTurkey} />
        }
        case 'Crossfit': {
            return <FaWeightHanging />
        }
        case 'Dancing, class':
        case 'Dancing, salsa/country/swing':
        case 'Dancing, party': {
            return <Icon icon={danceBallroom} />
        }
        case 'Fencing': {
            return <GiFencer />
        }
        case 'Firewood-carrying/chopping': {
            return <GiCampfire />
        }
        case 'Fishing': {
            return <GiBoatFishing />
        }
        case 'Soccer':
        case 'Soccer, recreational':
        case 'Soccer, competitive':
        case 'Kickball':
        case 'Football': {
            return <BiFootball />
        }
        case 'Frisbee': {
            return <GiFrisbee />
        }
        case 'Gardening': {
            return <GiFlowerPot />
        }
        case 'Golf':
        case 'Miniature golf':
        case 'Golf, carrying clubs':
        case 'Golf, powered cart': {
            return <GiGolfTee />
        }
        case 'Gymnastics': {
            return <CgGym />
        }
        case 'Hike':
        case 'Hiking':
        case 'Hiking, orienteering':
        case 'Backpacking': {
            return <FaHiking />
        }
        case 'Hockey, field and ice': {
            return <GiHockey />
        }
        case 'Home/auto repair': {
            return <FaWrench />
        }
        case 'Horseback riding': {
            return <FaHorse />
        }
        case 'Horseshoes': {
            return <GiHorseshoe />
        }
        case 'Vacuuming':
        case 'Scrubbing floors':
        case 'Housework, light': {
            return <GiVacuumCleaner />
        }
        case 'Inline Skate':
        case 'Ice Skate':
        case 'Ice skating, moderate':
        case 'Ice skating, general': {
            return <FaSkating />
        }
        case 'Jumping rope, moderate':
        case 'Jumping rope, fast': {
            return <GiJumpingRope />
        }
        case 'Kitesurf': {
            return <GiKite />
        }
        case 'Yard work':
        case 'Raking lawn/leaves':
        case 'Mowing lawn': {
            return <GiGrass />
        }
        case 'Painting (a room)': {
            return <GiPaintRoller />
        }
        case 'Rowing machine':
        case 'Rowing': {
            return <MdRowing />
        }
        case 'Rock Climbing': {
            return <FaMountain />
        }
        case 'Sail':
        case 'Sailing, boat and board': {
            return <GiSailboat />
        }
        case 'Squash': {
            return <GiTennisRacket />
        }
        case 'Scuba Diving': {
            return <GiScubaMask />
        }
        case 'Shopping': {
            return <FiShoppingCart />
        }
        case 'Snowboard': {
            return <FaSnowboarding />
        }
        case 'Nordic Ski':
        case 'Skiing, cross-country':
        case 'Skiing, light/moderate ': {
            return <FaSkiingNordic />
        }
        case 'Stair Stepper':
        case 'Stair climbing, up stairs':
        case 'Stair climbing, down stairs':
        case 'Stair climbing, machine ': {
            return <GiStairs />
        }
        case 'Surfing': {
            return <GiWaveSurfer />
        }
        case 'Skateboard': {
            return <GiSkateboard />
        }
        case 'Swim':
        case 'Swimming, freestyle':
        case 'Swimming, butterfly':
        case 'Swimming, leisure':
        case 'Swimming, treading water':
        case 'Swimming, backstroke': {
            return <FaSwimmer />
        }
        case 'Tennis': {
            return <GiTennisBall />
        }
        case 'Volleyball': {
            return <FaVolleyballBall />
        }
        case 'Walk':
        case 'Walking, average':
        case 'Walking, stroll': {
            return <FaWalking />
        }
        case 'Weight Training':
        case 'Weight lifting': {
            return <GiWeightLiftingUp />
        }
        case 'Rollerblading': {
            return <GiRollerSkate />
        }
        case 'Canoeing':
        case 'Kayaking': {
            return <GiCanoe />
        }
        case 'Walking': {
            return <FaWalking />
        }
        default: {
            return <FaRunning />
        }
    }
}
