import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import { Error } from '../../components/Utils'
import { updateActivityHistory } from '../../utils/auth/thunks'
import './style.css'
import Chip from '@material-ui/core/Chip'
import { ActivityDatePicker } from './activityDatePicker'
import { green } from '@material-ui/core/colors'
import { AddActivityMenu } from './addActivityMenu'
import { toast } from 'react-toastify'
import { Check } from '../../components/ToastContent'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            fullWidth: true,
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
        fullWidth: true,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

export const EditActivity = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const { categories, isLoading, fetchError } = useSelector(
        (state) => state.category
    )
    const { currentPeriod } = useSelector((state) => state.period.data)
    const [open, setOpen] = useState(false)
    const [selectedActivity, setSelectedActivity] = useState('')
    const [duration, setDuration] = useState('')
    const [date, setDate] = useState(new Date())

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    useEffect(() => {
        setDate(props.date)
        setOpen(props.openEdit)
        setDuration(props.duration)
    }, [props.date, props.duration, props.openEdit])

    const handleClose = (action) => {
        if (action === 'update') {
            dispatch(
                updateActivityHistory(
                    props.id,
                    selectedActivity,
                    duration,
                    date,
                    token,
                    currentPeriod.registrationStart,
                    currentPeriod.registrationEnd
                )
            ).then(() => {
                toast.success(<Check text="Activity updated!" />)
            })
        }
        props.resetEditOpen(false)
        setOpen(false)
    }

    const onActivityChange = (activity) => {
        setSelectedActivity(activity)
    }

    const onDurationChange = (event) => {
        setDuration(event.target.value)
    }

    const onDateChange = (date) => {
        setDate(date.toISOString())
    }

    const addTime = (time) => {
        setDuration(+duration + +time)
    }

    return (
        <>
            {!isLoading && fetchError && <Error />}
            {!fetchError && categories && (
                <>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                    >
                        <DialogTitle id="form-dialog-title">
                            Edit Activity
                        </DialogTitle>
                        <DialogContent>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <div className="container">
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <AddActivityMenu
                                                    onActivityChange={
                                                        onActivityChange
                                                    }
                                                    selectedActivity={
                                                        selectedActivity
                                                    }
                                                    categories={categories}
                                                    activityId={
                                                        props.activityId
                                                    }
                                                    activityName={
                                                        props.activityName
                                                    }
                                                />
                                            </div>
                                            <div className="row activity-sub-buttons">
                                                <Box
                                                    justifyContent="right"
                                                    width={1}
                                                ></Box>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="container">
                                            <div className="row">
                                                <TextField
                                                    style={{
                                                        minWidth: 240,
                                                        marginRight: 20,
                                                        width: 170,
                                                    }}
                                                    id="duration"
                                                    label="Duration"
                                                    variant="outlined"
                                                    value={duration}
                                                    onChange={onDurationChange}
                                                />
                                            </div>
                                            <div className="row activity-sub-buttons">
                                                <Box
                                                    display="flex"
                                                    justifyContent="right"
                                                    width={1}
                                                    pt={1}
                                                >
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+15min"
                                                            onClick={() =>
                                                                addTime(15)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+30min"
                                                            onClick={() =>
                                                                addTime(30)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+1hr"
                                                            onClick={() =>
                                                                addTime(60)
                                                            }
                                                        />
                                                    </div>
                                                    <div className="sub-cell">
                                                        <Chip
                                                            color="primary"
                                                            size="small"
                                                            label="+2hrs"
                                                            onClick={() =>
                                                                addTime(120)
                                                            }
                                                        />
                                                    </div>
                                                </Box>
                                            </div>
                                        </div>
                                    </div>
                                    <ActivityDatePicker
                                        onDateChange={onDateChange}
                                        date={props.date}
                                    />
                                </div>
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleClose()}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() => handleClose('update')}
                                color="secondary"
                                variant="outlined"
                            >
                                Update Activity
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )}
        </>
    )
}
