import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { PeriodConfigInstance } from './PeriodConfigInstance'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { getAllPeriods } from '../../utils/api/configurationApi'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}))

export const PeriodConfig = () => {
    const classes = useStyles()

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const [periods, setPeriods] = useState()

    useEffect(() => {
        getAllPeriods(token).then((result) => {
            setPeriods(result)
        })
    }, [token])

    return (
        <Container maxWidth="md">
            <Box justifyContent="left" pl={1}>
                <Box justifyContent="left" m={0} pt={3}>
                    <Box>
                        <h2>Periods</h2>
                    </Box>
                </Box>
                {/* <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                    <Button size="small" color="secondary" variant="outlined">
                        Add new period
                    </Button>
                </div> */}
                {periods !== undefined ? (
                    <div className={classes.root}>
                        {periods.map((period, index) => {
                            return (
                                <PeriodConfigInstance
                                    period={period}
                                    key={period.id}
                                    index={index + 1}
                                />
                            )
                        })}
                    </div>
                ) : (
                    ''
                )}
            </Box>
        </Container>
    )
}
