import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { ActivityConfigInstance } from './ActivityConfigInstance'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { getActivities } from '../../utils/api/configurationApi'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        maxHeight: 300,
    },
}))

export const ActivityConfig = () => {
    const classes = useStyles()

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const [categories, setCategories] = useState()

    useEffect(() => {
        getActivities(token).then((result) => {
            setCategories(result)
        })
    }, [token])

    return (
        <Container maxWidth="md">
            <Box justifyContent="left" pl={1}>
                <Box justifyContent="left" m={0} pt={3}>
                    <Box>
                        <h2>Activities</h2>
                    </Box>
                </Box>
                <TableContainer className={classes.container} component={Paper}>
                    <Table
                        stickyHeader
                        aria-label="collapsible table"
                        size="small"
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Enabled</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Steps/minute</TableCell>
                                <TableCell align="left">Source</TableCell>
                                <TableCell align="left">Save</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {categories === undefined ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                categories.map((row, index) => (
                                    <ActivityConfigInstance
                                        key={index}
                                        row={row}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    )
}
