import React from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export const PostResetPassword = () => {
    const classes = useStyles()

    const returnToHome = (e) => {
        return (
            <Redirect
                to={{
                    pathname: '/',
                }}
            />
        )
    }

    return (
        <article>
            <div className="pt-5">
                <div className="d-flex justify-content-center">
                    <h2 className="display-3">Email sent</h2>
                </div>
            </div>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                        textAlign="center"
                    >
                        <div style={{ textAlign: 'center' }}>
                            An email has been sent with instructions on how to
                            reset your password.
                        </div>
                    </Grid>
                    <a href="/">
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={(e) => returnToHome(e)}
                        >
                            Back to login
                        </Button>
                    </a>
                </div>
            </Container>
        </article>
    )
}
