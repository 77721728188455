import { createSlice } from '@reduxjs/toolkit'

const userSummarySlice = createSlice({
    name: 'userSummary',
    initialState: {
        isLoading: false,
        fetchSuccess: false,
        fetchError: false,
        error: {},
        summary: [{}],
    },
    reducers: {
        requestUserSummarySuccess(state, action) {
            state.isLoading = false
            state.fetchSuccess = true
            state.summary = action.payload
        },
    },
})

export const { requestUserSummarySuccess } = userSummarySlice.actions
export default userSummarySlice.reducer
