import React, { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PieChart, Pie, Cell, Sector } from 'recharts'

export const TeamSummaryGraph = memo((props) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const { unit } = useSelector((state) => state.user.config)

    const onPieEnter = (data, index) => {
        setActiveIndex(index)
    }

    const renderActiveShape = (props) => {
        const {
            cx,
            cy,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            payload,
        } = props

        return (
            <g>
                <text
                    x={cx}
                    y={cy}
                    dy={3}
                    textAnchor="middle"
                    fill="white"
                    fontSize="10px"
                >
                    {payload.key}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius + 5}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        )
    }

    return (
        <PieChart width={200} height={180}>
            <Pie
                activeIndex={activeIndex}
                activeShape={renderActiveShape}
                data={props.summary}
                cx={90}
                cy={85}
                innerRadius={55}
                outerRadius={85}
                strokeWidth={2}
                stroke="#0B1222"
                dataKey={`${unit === 'Time' ? 'value' : 'stepsEquivalent'}`}
                labelLine={true}
                onMouseEnter={(data, index) => onPieEnter(data, index)}
            >
                {props.summary?.map((entry, index) => {
                    debugger
                    return (
                        <Cell
                            key={`cell-${index}`}
                            fill={props.COLORS[index % props.COLORS.length]}
                        />
                    )
                })}
            </Pie>
        </PieChart>
    )
})
