import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export const SetUserStatusModal = (props) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (answer) => {
        if (answer === 'yes') {
            if (props.status === 'active') {
                props.onSetUserStatusActive(props.status)
            }

            if (props.status === 'inactive') {
                props.onSetUserStatusInactive(props.status)
            }
        }
        setOpen(false)
    }

    return (
        <div style={{ paddingRight: '10px' }}>
            <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                <Button
                    style={{ whiteSpace: 'nowrap' }}
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={handleClickOpen}
                >
                    Set all users to&nbsp;<strong>{props.status}</strong>
                </Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    ⚠️ {'Warning'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will set all users to have status = {props.status}.
                        An inactive user will not be assigned a team when that
                        happens.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose('yes')}
                        color="secondary"
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button onClick={handleClose} color="white">
                        No, cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
