import axios from 'axios'

var host = process.env.REACT_APP_BACK_API_URL

export const postUserConfig = (token, email) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { email }

    return axios
        .post(`${host}/user/config`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 201) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const getUserConfig = (token) => {
    return axios
        .get(`${host}/user/config`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((error) => {})
        .then((response) => response.data)
}

export const getAllCategories = (token) => {
    return axios
        .get(`${host}/activity/categories`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getAggregatedData = (token) => {
    return axios
        .get(`${host}/activity/aggregated`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getGroupedActivityHistory = (
    token,
    registrationStart,
    registrationEnd
) => {
    return axios
        .get(
            `${host}/activity/grouped?registrationStart=${registrationStart}&registrationEnd=${registrationEnd}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token, //the token is a variable which holds the token
                },
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const assignTeams = (token) => {
    return axios
        .get(`${host}/user/assignteams`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const unassignAllTeams = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/team/unassign`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const setAllUsersToInactive = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/user/setinactive`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const setAllUsersToActive = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/user/setactive`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getSummaryActivityHistory = (
    token,
    registrationStart,
    registrationEnd
) => {
    return axios
        .get(
            `${host}/activity/summary?registrationStart=${registrationStart}&registrationEnd=${registrationEnd}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token, //the token is a variable which holds the token
                },
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getActivities = (token) => {
    return axios
        .get(`${host}/activity`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getTeams = (token) => {
    return axios
        .get(`${host}/team`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getAudits = (token) => {
    return axios
        .get(`${host}/audit`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getUsers = (token) => {
    return axios
        .get(`${host}/user`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getCompany = (token, registrationStart, registrationEnd) => {
    return axios
        .get(
            `${host}/company/grouped?registrationStart=${registrationStart}&registrationEnd=${registrationEnd}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token, //the token is a variable which holds the token
                },
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getPeriodData = (token) => {
    return axios
        .get(`${host}/period/current`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const getTeamDataGrouped = (
    token,
    registrationStart,
    registrationEnd
) => {
    return axios
        .get(
            `${host}/team/grouped?registrationStart=${registrationStart}&registrationEnd=${registrationEnd}`,
            {
                headers: {
                    Authorization: 'Bearer ' + token, //the token is a variable which holds the token
                },
            }
        )
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const assignTeam = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/team/assign`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const setCurrentUserActive = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/user/activate`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const setUserInactive = (id, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/user/${id}/inactivate`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const setUserActive = (id, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/user/${id}/activate`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const switchFavorite = (token, activityId) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/favorite/${activityId}`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const submitOnboarding = (
    token,
    country,
    departmentId,
    level,
    hasdoneOnboarding,
    displayName
) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {
        country,
        departmentId,
        level,
        hasdoneOnboarding,
        displayName,
    }

    return axios
        .post(`${host}/user/onboarding`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const setUnit = (token, unit) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { unit }

    return axios
        .post(`${host}/user/unit`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const setTheme = (token, area, theme) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .put(`${host}/user/theme?area=${area}&theme=${theme}`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const switchOnboarding = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/user/onboarding?hasdone=true`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const putActivityHistory = (id, activityId, duration, date, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { activityId, duration, date }

    return axios
        .put(`${host}/activity/${id}`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const postActivityHistory = (activityId, duration, date, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { activityId, date, duration }

    return axios
        .post(`${host}/activity`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 201) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const deleteActivityHistory = (activityId, token) => {
    const headers = {
        Authorization: 'Bearer ' + token,
    }
    return axios
        .delete(`${host}/activity/${activityId}`, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const deleteUser = (userId, deleteUserData, token) => {
    const headers = {
        Authorization: 'Bearer ' + token,
    }
    return axios
        .delete(`${host}/user/${userId}?deleteUserData=${deleteUserData}`, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const deleteAllActivityHistory = (token) => {
    const headers = {
        Authorization: 'Bearer ' + token,
    }
    return axios
        .delete(`${host}/activity`, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const requestTokenGarmin = (token) => {
    return axios
        .get(`${host}/garmin/requesttoken`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const disconnectGarmin = (token) => {
    return axios
        .get(`${host}/garmin/disconnect`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}

export const reportGarminOauthToken = (
    token,
    garminOauthToken,
    oauthVerifier
) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { garminOauthToken, oauthVerifier }

    return axios
        .post(`${host}/garmin/reportoauthtoken`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const exchangeCodeForTokenStrava = (token, code) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { code }

    return axios
        .post(`${host}/strava/token`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const exchangeCodeForTokenFitbit = (token, code) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { code }

    return axios
        .post(`${host}/fitbit/token`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const setupFitbitSubscription = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = {}

    return axios
        .post(`${host}/fitbit/subscription`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const postFeedback = (title, text, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }

    var data = { title, text }

    return axios
        .post(`${host}/feedback`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 201) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const postError = (type, description, token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }
    var data = { type, description }

    return axios
        .post(`${host}/error`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const setFirstTimeDone = (token) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }
    var data = {}

    return axios
        .post(`${host}/user/firsttime`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const getDepartments = (token) => {
    return axios
        .get(`${host}/department`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((error) => {})
        .then((response) => response.data)
}
