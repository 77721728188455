import axios from 'axios'

var host = process.env.REACT_APP_BACK_API_URL

export const getActivities = (token) => {
    return axios
        .get(`${host}/settings/activity`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((error) => {})
        .then((response) => response.data)
}

export const getTeams = (token) => {
    return axios
        .get(`${host}/team`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((error) => {})
        .then((response) => response.data)
}

export const updateActivity = (
    id,
    name,
    garminName,
    enabled,
    stepsPerMinute,
    token
) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }
    var data = { id, name, garminName, enabled, stepsPerMinute }

    return axios
        .put(`${host}/settings/activity/${id}`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const updatePeriod = (
    id,
    freezeStart,
    freezeEnd,
    registrationStart,
    registrationEnd,
    ghostDays,
    token
) => {
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token,
    }
    var data = {
        id,
        freezeStart,
        freezeEnd,
        registrationStart,
        registrationEnd,
        ghostDays,
    }

    return axios
        .put(`${host}/period/${id}`, data, {
            headers: headers,
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .catch((e) => {
            console.log('Error it be: ', e)
        })
        .then((response) => response)
}

export const getAllPeriods = (token) => {
    return axios
        .get(`${host}/period`, {
            headers: {
                Authorization: 'Bearer ' + token, //the token is a variable which holds the token
            },
        })
        .then((response) => {
            if (response.status !== 200) {
                throw response
            }
            return response
        })
        .then((response) => response.data)
}
