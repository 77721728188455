import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSelector } from 'react-redux'
import Button from '@material-ui/core/Button'
import { requestTokenGarmin, disconnectGarmin } from '../../utils/api/api'
import garminLogo from '../../utils/resources/garminlogo.png'
import LaunchIcon from '@material-ui/icons/Launch'
import stravaLogo from '../../utils/resources/strava-logo.png'
import fitbitLogo from '../../utils/resources/Fitbit_logo_RGB.png'
import './style.css'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        color: '#416a82',
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2),
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    icon: {
        verticalAlign: 'bottom',
        height: 20,
        width: 20,
    },
    details: {
        alignItems: 'center',
    },
    columnMain: {
        flexBasis: '66.66%',
    },
    columnHelper: {
        flexBasis: '33.33%',
    },
    textField: {
        width: '100%',
        paddingRight: '14px',
    },
}))

export const Integrations = () => {
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState(false)

    var garminCallback = process.env.REACT_APP_GARMIN_CALLBACK
    var stravaCallback = process.env.REACT_APP_STRAVA_CALLBACK
    var stravaClientId = process.env.REACT_APP_STRAVA_CLIENTID

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const { integrations } = useSelector((state) => state.user.config)

    const onRequestToken = (name) => {
        switch (name) {
            case 'Garmin': {
                return requestTokenGarmin(token).then((response) => {
                    return window.location.replace(
                        `https://connect.garmin.com/oauthConfirm?oauth_token=${response.requestToken}&oauth_callback=${garminCallback}`
                    )
                })
            }
            case 'Strava': {
                return window.location.replace(
                    `http://www.strava.com/oauth/authorize?client_id=${stravaClientId}&response_type=code&redirect_uri=${stravaCallback}&approval_prompt=force&scope=activity:read_all`
                )
            }
            case 'Fitbit': {
                return window.location.replace(
                    `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22C4BL&redirect_uri=https%3A%2F%2Fwww.stronger-together.app%2Fapp%2Fintegrations%2Ffitbit%2Fcallback&scope=activity&expires_in=604800`
                )
            }
            default: {
                break
            }
        }
    }

    const onDisconnectService = (name) => {
        switch (name) {
            case 'Garmin': {
                return disconnectGarmin(token).then((response) => {
                    window.location.reload()
                })
            }
            case 'Strava': {
                window.open('https://www.strava.com/settings/apps', '_blank')
                break
            }
            case 'Fitbit': {
                window.open(
                    'https://www.fitbit.com/settings/applications',
                    '_blank'
                )
                break
            }

            default:
                break
        }
    }

    const renderLogo = (name) => {
        switch (name) {
            case 'Garmin':
                return 'Garmin'

            case 'Strava':
                return 'Strava'

            case 'Fitbit':
                return 'Fitbit'

            default:
                break
        }
    }

    const renderAccordions = (service) => {
        const { name, connected, connectedAt } = { ...service }

        return (
            <Accordion
                expanded={expanded === name}
                onChange={handleChange(name)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    id={`${name}-header`}
                >
                    <Typography className={classes.heading}>
                        {renderLogo(name)}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                        {connected
                            ? `Connected ${new Date(
                                  connectedAt
                              ).toLocaleDateString('en-GB', {
                                  dateStyle: 'long',
                              })} ${new Date(connectedAt).toLocaleTimeString(
                                  'no-NO'
                              )}`
                            : 'Not connected'}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div className={classes.columnMain}>
                        <Button
                            disabled={connected}
                            variant="outlined"
                            color="secondary"
                            onClick={() => onRequestToken(name)}
                        >
                            {connected ? 'Connected' : `Connect with ${name}`}
                        </Button>
                        <Button
                            style={{
                                marginLeft: '5px',
                                display: connected ? 'unset' : 'none',
                            }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => onDisconnectService(name)}
                        >
                            Disconnect
                        </Button>
                    </div>
                </AccordionDetails>
            </Accordion>
        )
    }

    return (
        <Container maxWidth="md">
            <Box justifyContent="left" pl={1}>
                <Box justifyContent="left" m={0} pt={3}>
                    <Box>
                        <h2>Integrations</h2>
                    </Box>
                </Box>

                <div className={classes.root}>
                    {integrations.map((service) => {
                        return renderAccordions(service)
                    })}
                </div>
            </Box>
        </Container>
    )
}
