import React, { useState, memo } from 'react'
import { UnitDisplayer } from '../../components/UnitDisplayer'
import {
    Cell,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    LabelList,
} from 'recharts'
import './style.css'
import { TitleDisplayer } from '../../components/TitleDisplayer'

const renderCustomizedLabel = (props) => {
    const { x, y, width, value } = props
    return (
        <g>
            <text
                x={x + width - width / 14}
                y={y + 10}
                fill="#000"
                textAnchor="end"
                dominantBaseline="middle"
            >
                {value}
            </text>
        </g>
    )
}

export const CompanyGraph = memo((props) => {
    const [isAnimation, setIsAnimation] = useState(true)

    const CustomTooltip = ({ active, payload, label }) => {
        if (active) {
            return (
                <div className="custom-tooltip">
                    <div>
                        <strong>{`${label}`}</strong>
                    </div>
                    <TitleDisplayer unit={props.unit} />:{' '}
                    <UnitDisplayer
                        unit={props.unit}
                        valueInTime={payload[0].value}
                        valueInSteps={payload[0].payload.totalStepsEquivalent}
                    />
                </div>
            )
        }
        return null
    }
    return (
        <BarChart
            layout="vertical"
            isAnimationActive={isAnimation}
            onAnimationEnd={() => setIsAnimation(false)}
            width={250}
            height={180}
            data={props.shortListSummary}
            margin={{
                top: 5,
                right: 0,
                left: 0,
                bottom: 5,
            }}
        >
            <CartesianGrid stroke="rgba(255, 255, 255, 0.205)" />
            <XAxis axisLine={true} type="number" />
            <YAxis
                hide
                dataKey="team.name"
                type="category"
                axisLine={false}
                tickLine={false}
            />
            <Tooltip
                content={
                    <CustomTooltip shortListSummary={props.shortListSummary} />
                }
            />
            <Bar
                dataKey={
                    props.unit === 'Time'
                        ? 'totalDuration'
                        : props.unit === 'Points'
                        ? 'totalPointsEquivalent'
                        : 'totalStepsEquivalent'
                }
                fill="#8884d8"
                minPointSize={5}
            >
                <LabelList
                    dataKey="team.name"
                    content={renderCustomizedLabel}
                />
                {props.shortListSummary.map((entry, index) => (
                    <Cell
                        key={`cell-${index}`}
                        fill={props.colors[index % 20]}
                    />
                ))}
            </Bar>
        </BarChart>
    )
})
