import React from 'react'
import './style.css'

export const PropertyBox = (props) => {
    const { value, label } = props
    return (
        <div className="container">
            <div className="row propertyRow">
                <div className="col col-4 propertyLabel">{label}</div>
                <div className="col propertyValue">{String(value)}</div>
            </div>
        </div>
    )
}
