import { createSlice } from '@reduxjs/toolkit'

const userSlice = createSlice({
    name: 'user',
    initialState: {
        isLoading: false,
        fetchSuccess: false,
        fetchError: false,
        error: {},
        config: [{}],
    },
    reducers: {
        createUserConfig(state) {
            state.isLoading = true
            state.fetchSuccess = false
            state.fetchError = false
        },
        createUserConfigSuccess(state) {
            state.isLoading = false
            state.fetchSuccess = true
            state.fetchError = false
        },
        requestUserConfig(state) {
            state.isLoading = true
            state.fetchSuccess = false
            state.fetchError = false
        },
        requestUserConfigSuccess(state, action) {
            state.isLoading = false
            state.fetchSuccess = true
            state.config = action.payload
        },
    },
})

export const {
    requestUserConfig,
    requestUserConfigSuccess,
    createUserConfigSuccess,
    createUserConfig,
} = userSlice.actions
export default userSlice.reducer
