import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserConfig } from '../../utils/auth/thunks'
import 'react-circular-progressbar/dist/styles.css'
import { Redirect } from 'react-router-dom'
import { postUserConfig } from '../../utils/api/api'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'

export const Selector = () => {
    const dispatch = useDispatch()
    const { email, emailVerified } = useSelector((state) => state.auth.user)
    const [config, setConfig] = useState()

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    useEffect(() => {
        dispatch(fetchUserConfig(token, email)).then((userConfig) => {
            if (userConfig?.length === 0) {
                postUserConfig(token, email)
                    .then((config) => {
                        setConfig(config.data)
                    })
                    .catch((error) => {
                        // User exist, try fetching again
                        fetchUserConfig((token, email)).then((userConfig) => {
                            setConfig(userConfig)
                        })
                    })
            } else {
                setConfig(userConfig)
            }
        })
    }, [dispatch, email, token])

    if (!emailVerified) {
        return window.location.replace('../verifyemail')
    } else if (config && config.hasDoneOnboarding === false) {
        return (
            <Redirect
                to={{
                    pathname: 'onboarding',
                }}
            />
        )
    } else if (config) {
        return window.location.replace('../app/home')
    } else
        return (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
            >
                <CircularProgress color="secondary" size={100} />
            </Grid>
        )
}
