import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './index.css'
import UserProvider from '../utils/auth/UserProvider'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { lightBlue, deepPurple } from '@material-ui/core/colors'
import { myFirebase } from '../utils/auth/Firebase'
import { successLogin, successVerify } from '../utils/auth/authSlice'
import { useDispatch } from 'react-redux'
import 'fontsource-roboto'
import { PublicLayout } from './PublicLayout'
import { ProtectedLayout } from './ProtectedLayout'
import { IntroLayout } from './IntroLayout'
import { SnackBarProvider } from '../components/SnackbarProvider'
import { ToastContainer, Slide } from 'react-toastify'
import { CornerRibbon } from '../components/cornerRibbon/CornerRibbon'

import 'react-toastify/dist/ReactToastify.css'

export const App = () => {
    var environment = process.env.REACT_APP_ENV
    const dispatch = useDispatch()
    const [isLoading] = useState(false)
    const [darkState, setDarkState] = useState(true)
    const palletType = darkState ? 'dark' : 'light'
    const mainPrimaryColor = darkState ? '#1B2C36' : lightBlue[100]
    const mainSecondaryColor = darkState ? '#FD6100' : deepPurple[500]
    const darkTheme = createMuiTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor,
            },
            secondary: {
                main: mainSecondaryColor,
            },
        },
    })

    const handleThemeChange = () => {
        setDarkState(!darkState)
    }

    myFirebase
        .auth()
        .getRedirectResult()
        .then((result) => {
            if (result.user) {
                dispatch(successLogin(JSON.stringify(result.user)))
            }
        })

    myFirebase.auth().onAuthStateChanged((user) => {
        if (user !== null) {
            dispatch(successLogin(JSON.stringify(user)))
        }
        dispatch(successVerify())
    })

    if (isLoading) {
        return (
            <div className="container bordered centered">
                <div>
                    <i className="fas fa-spinner fa-spin fa-3x" />
                </div>
            </div>
        )
    }

    return (
        <SnackBarProvider>
            <UserProvider>
                <Router>
                    <ThemeProvider theme={darkTheme}>
                        <div id="App" className="appwrapper">
                            <div id="page-wrap">
                                {(environment === 'DEV' ||
                                    environment === 'TEST') && (
                                    <CornerRibbon env={environment} />
                                )}
                                <ToastContainer
                                    position="bottom-center"
                                    autoClose={5000}
                                    hideProgressBar={true}
                                    newestOnTop={true}
                                    closeOnClick
                                    rtl={false}
                                    pauseOnFocusLoss
                                    draggable
                                    pauseOnHover
                                    transition={Slide}
                                />
                                <Switch>
                                    <Route
                                        path="/app"
                                        component={ProtectedLayout}
                                    />
                                    <Route
                                        path="/intro"
                                        component={IntroLayout}
                                    />
                                    <Route path="/" component={PublicLayout} />
                                </Switch>
                            </div>
                        </div>
                    </ThemeProvider>
                </Router>
            </UserProvider>
        </SnackBarProvider>
    )
}
