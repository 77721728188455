import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Button from '@material-ui/core/Button'
import AccountCircle from '@material-ui/icons/AccountCircle'
import FaceIcon from '@material-ui/icons/Face'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import SettingsIcon from '@material-ui/icons/Settings'
import SecurityIcon from '@material-ui/icons/Security'
import { useDispatch, useSelector } from 'react-redux'
import { logoutUser } from '../../utils/auth/thunks'
import './style.css'
import Link from '@material-ui/core/Link'
import IconButton from '@material-ui/core/IconButton'
import { GiveFeedBack } from '../feedback/GiveFeedback'

const useStyles = makeStyles((theme) => ({
    typography: {
        padding: theme.spacing(2),
    },
    buttons: {
        width: 'inherit',
    },
}))

export const UserPopover = (props) => {
    var buildVersion = process.env.REACT_APP_BUILD_VERSION
    var env = process.env.REACT_APP_ENV
    const { user } = useSelector((state) => state.auth)
    const { role, name, email } = useSelector((state) => state.user.config)
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const dispatch = useDispatch()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const signOut = (e) => {
        e.preventDefault()
        dispatch(logoutUser())
    }

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const renderAdminSettings = () => {
        return (
            <div className="row">
                <div className="col profile-name-box profile-box-center">
                    <Link href="/app/configuration" variant="body2">
                        <Button
                            className="no-outline"
                            size="large"
                            startIcon={<SecurityIcon />}
                        >
                            Configuration
                        </Button>
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <div>
            <IconButton onClick={handleClick} aria-label="delete">
                <AccountCircle />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.typography}>
                    <div className="profile-picture-box profile-box-center">
                        {user?.photoURL ? (
                            <img
                                src={user?.photoURL}
                                className="header-profile-picture-large"
                                alt={'UserImage'}
                            />
                        ) : (
                            <img
                                src={`https://eu.ui-avatars.com/api/?background=random&name=${name}&rounded=true&size=100&bolde=true`}
                                alt="UserImage"
                            />
                        )}
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col profile-name-box profile-box-center">
                                <h6>
                                    <strong>{name}</strong>
                                </h6>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col profile-email-box profile-box-center">
                                {email}
                            </div>
                        </div>
                    </div>

                    <hr style={{ borderTop: '1px solid rgb(109 108 108' }} />
                    <div className="container">
                        {role === 'Admin' && renderAdminSettings()}
                        <div className="row">
                            <div className="col profile-name-box profile-box-center">
                                <Link href="/app/settings" variant="body2">
                                    <Button
                                        className={classes.buttons}
                                        size="large"
                                        startIcon={<SettingsIcon />}
                                    >
                                        Settings
                                    </Button>
                                </Link>
                            </div>
                        </div>

                        <GiveFeedBack />

                        <div className="row">
                            <div className="col profile-name-box profile-box-center">
                                <Button
                                    className={classes.buttons}
                                    size="large"
                                    startIcon={<ExitToAppIcon />}
                                    onClick={(e) => signOut(e)}
                                >
                                    Log Out
                                </Button>
                            </div>
                        </div>
                    </div>
                    <hr style={{ borderTop: '1px solid rgb(109 108 108' }} />
                    <div
                        pt={4}
                        style={{
                            fontSize: 'x-small',
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#666',
                        }}
                    >
                        <strong>
                            v.: {buildVersion}-{env}
                        </strong>
                    </div>
                </div>
            </Popover>
        </div>
    )
}
