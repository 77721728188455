import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import IconButton from '@material-ui/core/IconButton'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { DeleteUserModal } from './DeleteUserModal'
import Switch from '@material-ui/core/Switch'
import { setUserInactive, setUserActive } from '../../utils/api/api'

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
})

export const AuditConfigInstance = (props) => {
    const classes = useRowStyles()
    const { audit } = props
    const [open, setOpen] = React.useState(false)
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {audit.id}
                </TableCell>
                <TableCell component="th" scope="row">
                    {audit.date}
                </TableCell>
                <TableCell component="th" scope="row">
                    {audit.topic}
                </TableCell>
                <TableCell component="th" scope="row">
                    {audit.action}
                </TableCell>
                <TableCell component="th" scope="row">
                    {audit.user}
                </TableCell>
                <TableCell component="th" scope="row">
                    {audit.details}
                </TableCell>
            </TableRow>
        </React.Fragment>
    )
}
