import React, { createContext, useState, useEffect } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

const AUTO_DISMISS = 5000
export const SnackBarContext = createContext()

export const SnackBarProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([])
    const [show, setShow] = useState(false)

    const activeAlertIds = alerts.join(',')
    useEffect(() => {
        if (activeAlertIds.length > 0) {
            const timer = setTimeout(
                () => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)),
                AUTO_DISMISS
            )
            return () => clearTimeout(timer)
        }
    }, [activeAlertIds])

    const addAlert = (alert) => {
        setAlerts((alerts) => [alert, ...alerts])
        setShow(true)
    }

    const value = { addAlert }

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />
    }

    return (
        <SnackBarContext.Provider value={value}>
            {children}
            {alerts.map((alert, index) => (
                <Snackbar
                    key={index}
                    open={show}
                    onClose={() => setShow(false)}
                    autoHideDuration={4000}
                >
                    <Alert
                        onClose={() => setShow(false)}
                        severity={alert.severity}
                    >
                        {alert.text}
                    </Alert>
                </Snackbar>
            ))}
        </SnackBarContext.Provider>
    )
}
