import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import { useSelector } from 'react-redux'
import { getUsers, assignTeams } from '../../utils/api/api'
import { UserConfigInstance } from './UserConfigInstance'
import CircularProgress from '@material-ui/core/CircularProgress'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import { toast } from 'react-toastify'
import { Check, Error } from '../../components/ToastContent'
import { AssignTeamModal } from './AssignTeamModal'
import { SetUserStatusModal } from './SetUserStatusModal'
import {
    setAllUsersToInactive,
    unassignAllTeams,
    setAllUsersToActive,
    deleteUser,
} from '../../utils/api/api'
import { ClearTeamsModal } from './ClearTeamsModal'
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    container: {
        maxHeight: 400,
    },
}))

export const UserConfig = () => {
    const classes = useStyles()

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )
    const [users, setUsers] = useState()

    const onClearAllTeams = () => {
        unassignAllTeams(token)
            .then(() => {
                toast.success(<Check text="All teams have been cleared" />)
                getUsers(token).then((result) => {
                    setUsers(result)
                })
            })
            .catch((error) => {
                toast.error(<Error text="Could not clear teams" />)
            })
    }

    const onAssignTeams = () => {
        assignTeams(token)
            .then((result) => {
                toast.success(
                    <Check text="All users have been assigned teams" />
                )
                getUsers(token).then((result) => {
                    setUsers(result)
                })
            })
            .catch((error) => {
                toast.error(<Error text="Could not assign teams" />)
            })
    }

    const onSetUserStatusInactive = () => {
        setAllUsersToInactive(token)
            .then((result) => {
                toast.success(
                    <Check text="All users now have status inactive" />
                )
                getUsers(token).then((result) => {
                    setUsers(result)
                })
            })
            .catch((error) => {
                toast.error(<Error text="Could set status for all users" />)
            })
    }

    const onSetUserStatusActive = () => {
        setAllUsersToActive(token)
            .then((result) => {
                toast.success(<Check text="All users now have status active" />)
                getUsers(token).then((result) => {
                    setUsers(result)
                })
            })
            .catch((error) => {
                toast.error(<Error text="Could set status for all users" />)
            })
    }

    const handleDeleteUser = (userId, deleteUserData) => {
        debugger
        deleteUser(userId, deleteUserData, token)
            .then(() => {
                toast.success(<Check text="User has been deleted" />)
                getUsers(token).then((result) => {
                    setUsers(result)
                })
            })
            .catch((error) => {
                toast.error(<Error text="Could not delete user" />)
            })
    }

    useEffect(() => {
        getUsers(token).then((result) => {
            setUsers(result)
        })
    }, [token])

    return (
        <Container maxWidth="md">
            <Box justifyContent="left" pl={1}>
                <Box justifyContent="left" m={0} pt={3}>
                    <Box>
                        <h2>
                            Users{' '}
                            {users?.allUsers?.length &&
                                `(${users?.allUsers?.length})`}
                        </h2>
                        <div>
                            Active users: <strong>{users?.activeUsers}</strong>
                        </div>
                        <div>
                            Inactive users:{' '}
                            <strong>
                                {users?.allUsers?.length - users?.activeUsers}
                            </strong>
                        </div>
                        <div>
                            Users with a team:{' '}
                            <strong>{users?.usersWithTeam}</strong>
                        </div>
                        <div>
                            Users without a team:{' '}
                            <strong>
                                {users?.allUsers?.length - users?.usersWithTeam}
                            </strong>
                        </div>
                    </Box>
                </Box>
                <div
                    className="container"
                    style={{ padding: 0, margin: 0, display: 'flex' }}
                >
                    <div className="row" style={{ marginLeft: '0' }}>
                        <div className="col" style={{ display: 'contents' }}>
                            <SetUserStatusModal
                                onSetUserStatusActive={onSetUserStatusActive}
                                status="active"
                            />
                            <SetUserStatusModal
                                onSetUserStatusInactive={
                                    onSetUserStatusInactive
                                }
                                status="inactive"
                            />
                            <ClearTeamsModal
                                onClearAllTeams={onClearAllTeams}
                            />
                            <AssignTeamModal onAssignTeams={onAssignTeams} />
                        </div>
                    </div>
                </div>
                <TableContainer component={Paper} className={classes.container}>
                    <Table aria-label="collapsible table" stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell />
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Team</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.allUsers === undefined ? (
                                <CircularProgress color="secondary" />
                            ) : (
                                users?.allUsers?.map((user) => (
                                    <UserConfigInstance
                                        user={user}
                                        handleDeleteUser={handleDeleteUser}
                                    />
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    )
}
