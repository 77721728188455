import React, { useState } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { HomePage } from '../pages/home/HomePage'
import { ErrorPage } from '../pages/error/ErrorPage'
import { NotFoundPage } from '../pages/error/NotFoundPage'
import './index.css'
import { Page404 } from '../components/Utils'
import { createMuiTheme } from '@material-ui/core/styles'
import { pink, lightBlue, deepPurple } from '@material-ui/core/colors'
import 'fontsource-roboto'
import { Settings } from '../pages/user/settings'
import { Header } from '../pages/header/Header'
import { PlayPage } from '../pages/play/PlayPage'
import { Footer } from '../pages/footer/footer'
import { GarminCallback } from '../pages/external/garmin/GarminCallback'
import { StravaCallback } from '../pages/user/stravaCallback'
import { FitbitCallback } from '../pages/user/fitbitCallback'
import { ConfigurationSelector } from '../pages/configuration/ConfigurationSelector'
import { DataConfig } from '../pages/configuration/DataConfig'

export const ProtectedLayout = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
    const isVerifying = useSelector((state) => state.auth.isVerifying)
    const [darkState, setDarkState] = useState(true)
    const palletType = darkState ? 'dark' : 'light'
    const mainPrimaryColor = darkState ? pink[500] : lightBlue[500]
    const mainSecondaryColor = darkState ? lightBlue[100] : deepPurple[500]
    const darkTheme = createMuiTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor,
            },
            secondary: {
                main: mainSecondaryColor,
            },
        },
    })
    const handleThemeChange = () => {
        setDarkState(!darkState)
    }

    const PrivateRoute = ({ component: Component, ...rest }) => {
        return (
            <Route
                {...rest}
                render={(props) =>
                    isVerifying ? (
                        <div />
                    ) : isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/',
                                state: { from: props.location },
                            }}
                        />
                    )
                }
            />
        )
    }

    return (
        <>
            <PrivateRoute component={Header} />
            <Switch checked={darkState} onChange={handleThemeChange} />
            <Switch>
                <PrivateRoute exact path="/app/home" component={HomePage} />
                <PrivateRoute exact path="/app/settings" component={Settings} />
                <PrivateRoute
                    exact
                    path="/app/integrations/garmin/callback"
                    component={GarminCallback}
                />
                <PrivateRoute
                    exact
                    path="/app/integrations/strava/codecallback"
                    component={StravaCallback}
                />
                <PrivateRoute
                    exact
                    path="/app/integrations/fitbit/callback"
                    component={FitbitCallback}
                />
                <PrivateRoute
                    exact
                    path="/app/configuration/data"
                    component={DataConfig}
                />
                <PrivateRoute
                    exact
                    path="/app/configuration"
                    component={ConfigurationSelector}
                />
                <PrivateRoute exact path="/app/play" component={PlayPage} />
                <Route path="error" component={ErrorPage} />
                <Route path="notfound" component={NotFoundPage} />
                <Route component={Page404} />
            </Switch>
            {/* <PrivateRoute component={Footer} /> */}
        </>
    )
}
