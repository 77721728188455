import React from 'react'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Divider from '@material-ui/core/Divider'
import { QuestionList } from './questionList'
import { DataCollection } from './dataCollection'
import './style.css'

export const Privacy = () => {
    return (
        <Container maxWidth="md">
            <Box justifyContent="left" m={0} pl={2}>
                <Box>
                    <h1>Privacy Notice</h1>
                </Box>
            </Box>

            <Divider variant="middle" />
            <Divider variant="middle" />
            <Box justifyContent="left" m={0} pl={2}>
                <Box>
                    <i style={{ color: '#666' }}>
                        For Users of Allente’s Stronger Together App
                    </i>
                </Box>
            </Box>
            <Box justifyContent="left" m={0} pl={2} pt={3}>
                <Box id="fullPrivacy" style={{ color: '#FD6100' }}>
                    <h5>Data Controller</h5>
                </Box>
            </Box>
            <Divider variant="middle" />
            <Box justifyContent="left" m={0} p={2}>
                <Box>
                    <div>VCB Sweden AB</div>
                    <div>Västra Järnvägsgatan 15 </div>
                    <div>111 64 Stockholm</div>
                    <div>Sweden</div>
                    <div>Company Registration Number: 559218-1308</div>
                    <div>
                        Data Protection Officer:{' '}
                        <a href="mailto:dpo@allente.tv">dpo@allente.tv</a>
                    </div>
                </Box>
            </Box>
            <Box justifyContent="left" m={0} pl={2} pt={2}>
                <Box>
                    <div style={{ color: '#FD6100' }}>
                        <strong>
                            <h5>
                                When does this policy apply, what is the purpose
                                of the processing, what types of personal data
                                do we collect, what are the legal grounds and
                                the retention time for the processing?
                            </h5>
                        </strong>
                    </div>
                </Box>
            </Box>
            <Divider variant="middle" />
            <Box justifyContent="left" m={0} p={2}>
                <Box>
                    <p>
                        This Privacy Notice applies when we collect, use and
                        otherwise process your personal data in relation to
                        Allente’s Stronger Together App.
                    </p>
                    <p>
                        The data we process is collected directly from you when
                        you use the Stronger Together App and participate in
                        Allente’s Health Competition.
                    </p>
                    <p>
                        If you choose to allow connectivity with 3rd party
                        fitness trackers (such as Fitbit, Apple Watch and
                        Garmin), limited data will also be collected from such
                        3rd parties.
                    </p>
                </Box>
            </Box>
            <Box justifyContent="left" m={0} p={2}>
                <Box>
                    <table>
                        <tr>
                            <th>Purpose of the processing</th>
                            <th>Types of personal data</th>
                            <th>Legal grounds</th>
                            <th>Retention</th>
                        </tr>
                        <tr>
                            <td>
                                To voluntarily register Allente’s employee’s
                                activity level to allow them to compete on a
                                team level with other employees.
                            </td>
                            <td>
                                Contact information: Personal E-mail, Personal
                                Identification: Full name (Voluntary) Employment
                                Information: Busines unit / division, Company/
                                entity, Office location (i.e. country) Activity
                                related information: Steps, Type of Activity,
                                Distance
                            </td>
                            <td>Your consent (Art. 6(1)(a))</td>
                            <td>Your personal data is stored for 12 months.</td>
                        </tr>
                    </table>
                </Box>
            </Box>
            <Box justifyContent="left" m={0} pl={2}>
                <Box>
                    <div style={{ color: '#FD6100' }}>
                        <strong>
                            <h5>
                                Will we share your personal data with anyone?
                            </h5>
                        </strong>
                    </div>
                </Box>
            </Box>
            <Divider variant="middle" />
            <Box justifyContent="left" m={0} p={2}>
                <Box>
                    <p>
                        We share your personal data with our application
                        developer, who is our data processor and is acting under
                        our instructions: Karsten Helmsgård-Rennes ; ITverket
                        AS, Lille Grensen 5, 0159 Oslo; T: (+47) 22 86 21 10 E:
                        post[at]itverket.no; org.nr.: 981 874 714 Furthermore,
                        we may share your data with suppliers located outside
                        the EU/EEA, such as Microsoft or Amazon Web Services. We
                        ensure that an adequate level of protection is
                        maintained, and that suitable safeguards are adopted.
                        This means that such transfer will be based on an
                        adequacy decision by the European Commission or by
                        entering into the European Commission’s standard
                        contractual clauses.
                    </p>
                    <p>
                        Your personal data may also be shared with other
                        companies within the Allente Group, based on Allente’s
                        legitimate interest (Art. 6(1)(f)) for internal
                        administrative purposes.
                    </p>
                </Box>
            </Box>
            <Box justifyContent="left" m={0} pl={2}>
                <Box>
                    <div style={{ color: '#FD6100' }}>
                        <strong>
                            <h5>How we protect your personal data?</h5>
                        </strong>
                    </div>
                </Box>
            </Box>
            <Divider variant="middle" />
            <Box justifyContent="left" m={0} p={2}>
                <Box>
                    <p>
                        We are committed to protecting your personal data and
                        have implemented appropriate technical and
                        organizational security measures to ensure the
                        protection of your personal data.
                    </p>
                </Box>
            </Box>
            <Box justifyContent="left" m={0} pl={2}>
                <Box>
                    <div style={{ color: '#FD6100' }}>
                        <strong>
                            <h5>Your rights</h5>
                        </strong>
                    </div>
                </Box>
            </Box>
            <Divider variant="middle" />
            <Box justifyContent="left" m={0} p={2}>
                <Box>
                    <p>
                        Under the GDPR you have certain rights you may exercise
                        concerning the processing of your personal data.
                    </p>
                    <p>
                        <strong>Right to access: </strong>
                        You have the right to obtain confirmation as to whether
                        we are processing your personal data and, if this should
                        be the case, access to that personal data.
                    </p>
                    <p>
                        <strong>Right to withdraw consent: </strong>
                        If a processing of your personal data is based on your
                        consent as set out you have the right to withdraw your
                        consent at any time.
                    </p>
                    <p>
                        <strong>Right to rectification: </strong>
                        You have the right to obtain rectification of inaccurate
                        or incomplete personal data concerning you.
                    </p>
                    <p>
                        <strong>
                            Right to erasure (“right to be forgotten”):{' '}
                        </strong>
                        Under certain circumstances you have the right to obtain
                        the erasure of personal data concerning you.
                    </p>
                    <p>
                        <strong>Right to restriction: </strong>
                        Under certain circumstances you have the right to obtain
                        the restriction of processing.
                    </p>
                    <p>
                        <strong>Right to data portability: </strong>
                        Under certain circumstances you have the right to
                        receive a copy of the personal data concerning you,
                        which you have provided to us, in a structured, commonly
                        used and machine-readable format and the right to
                        transmit this data to another controller.
                    </p>
                    <p>
                        <strong>Right to object: </strong>
                        Under certain circumstances you have the right to object
                        to the processing of personal data concerning you. This
                        includes but is not limited to the right to object in
                        cases in which we process your personal data based on
                        legitimate interest.
                    </p>
                    <p>
                        <strong>Right to lodge a complaint: </strong>
                        If your case is not resolved by us and you wish to
                        proceed with the case, you may lodge a complaint with
                        the Swedish Authority for Privacy Protection
                        (Integritetsskyddsmyndigheten, Box 8114, 104 20
                        Stockholm, +46 (0)8 657 61 00, e-mail: imy@imy.se), if
                        you consider that our processing of personal data about
                        you, infringes the GDPR
                    </p>
                </Box>
            </Box>
        </Container>
    )
}
