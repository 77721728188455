import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/core/styles'
import { UserPopover } from './UserPopover'
import Link from '@material-ui/core/Link'
import HomeIcon from '@material-ui/icons/Home'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { GiFootprint } from 'react-icons/gi'
import { BiRuler, BiTimeFive } from 'react-icons/bi'
import { changeUnit } from '../../utils/auth/thunks'
import { FaHashtag } from 'react-icons/fa'

import { ViewMyTeam } from '../team/viewMyTeam'
import './style.css'

const useStyles = makeStyles((theme) => ({
    toolbarButtons: {
        marginLeft: 'auto',
    },
    iconsContainer: {
        display: 'Inline-Flex',
        justifyContent: 'flex-end',
    },
}))

const renderUnitIcon = (unit) => {
    switch (unit) {
        case 'Steps': {
            return <GiFootprint />
        }
        case 'Time': {
            return <BiTimeFive />
        }
        case 'Points': {
            return <FaHashtag />
        }
        default: {
            return <HomeIcon />
        }
    }
}

export const Header = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const { unit } = useSelector((state) => state.user.config)
    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const handleUnitChange = () => {
        var newUnit
        if (unit === 'Steps') newUnit = 'Time'
        else if (unit === 'Time') newUnit = 'Points'
        else newUnit = 'Steps'

        dispatch(changeUnit(token, newUnit))
    }

    return (
        <AppBar position="relative" className="top-appbar">
            <Toolbar className="main-title" style={{ paddingRight: '0' }}>
                <Container maxWidth="md">
                    <div className="row">
                        <div className="col col-6 top-appbar-main-title">
                            <a href="/app/home">
                                <strong>
                                    <i>Stronger Together</i>
                                </strong>
                            </a>
                        </div>
                        <div className="col col-6">
                            <div className={classes.toolbarButtons}>
                                <Container className={classes.iconsContainer}>
                                    <Box>
                                        <ViewMyTeam area="Team" show={true} />
                                    </Box>
                                    <Box>
                                        <Tooltip title={unit} arrow>
                                            <IconButton
                                                className="no-outline"
                                                onClick={() =>
                                                    handleUnitChange()
                                                }
                                                aria-label="delete"
                                            >
                                                {renderUnitIcon(unit)}
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                    <Box>
                                        <Link href="/app/home">
                                            <IconButton
                                                className="no-outline"
                                                aria-label="delete"
                                            >
                                                <HomeIcon />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                    <Box>
                                        <UserPopover />
                                    </Box>
                                </Container>
                            </div>
                        </div>
                    </div>
                </Container>
            </Toolbar>
        </AppBar>
    )
}
