import React from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import { VscError } from 'react-icons/vsc'
import { RiErrorWarningLine } from 'react-icons/ri'

export const Check = (props) => (
    <div>
        <div className="container">
            <div className="row">
                <div className="col col-2">
                    <FaCheckCircle style={{ fontSize: '30px' }} />
                </div>
                <div
                    className="col"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {props.text}
                </div>
            </div>
        </div>
    </div>
)

export const Error = (props) => (
    <div>
        <div className="container">
            <div className="row">
                <div className="col col-2">
                    <VscError style={{ fontSize: '30px' }} />
                </div>
                <div
                    className="col"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {props.text}
                </div>
            </div>
        </div>
    </div>
)

export const Warning = (props) => (
    <div>
        <div className="container">
            <div className="row">
                <div className="col col-2">
                    <RiErrorWarningLine style={{ fontSize: '30px' }} />
                </div>
                <div
                    className="col"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {props.text}
                </div>
            </div>
        </div>
    </div>
)
