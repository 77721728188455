import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { PersonalPageSummary } from './PersonalPageSummary'
import { MyTeam } from '../team/myTeam'
import { MyCompany } from '../company/myCompany'
import { useSelector } from 'react-redux'
import { ErrorBoundary } from '../../components/ErrorBoundary'
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

export const SectorTabs = () => {
    const [value, setValue] = React.useState(1)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleChangeIndex = (index) => {
        setValue(index)
    }

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const { teamName } = useSelector((state) => state.team?.data)

    return (
        <div>
            <Box pt={10}>
                <Tabs value={value} fullWidth onChange={handleChange} centered>
                    <Tab label={teamName} />
                    <Tab label="You" />
                    <Tab label="Allente" />
                </Tabs>
                <SwipeableViews index={value} onChangeIndex={handleChangeIndex}>
                    <div>
                        <ErrorBoundary token={token}>
                            <MyTeam />
                        </ErrorBoundary>
                    </div>
                    <div style={{ overflow: 'hidden' }}>
                        <PersonalPageSummary />
                    </div>
                    <div>
                        <MyCompany />
                    </div>
                </SwipeableViews>
            </Box>
        </div>
    )
}
