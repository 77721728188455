import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { UnitDisplayer } from '../../components/UnitDisplayer'
import { TitleDisplayer } from '../../components/TitleDisplayer'
import Grid from '@material-ui/core/Grid'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { TeamSummaryGraph } from './teamSummaryGraph'
import { ViewMyTeam } from './viewMyTeam'
import { assignToTeam } from '../../utils/auth/thunks'
import { SettingsGraph } from '../home/SettingsGraph'
import { ThemeProvider } from '../../components/ThemeProvider'
import './style.css'

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: '#0b1222',
        padding: theme.spacing(5, 0, 0),
    },
}))

export const MyTeam = memo((props) => {
    const classes = useStyles()
    const { unit } = useSelector((state) => state.user.config)
    const { activitiesGrouped } = useSelector((state) => state.team?.data)
    const { data } = useSelector((state) => state.team)
    const { team } = useSelector((state) => state.user.config)
    const [summary, setSummary] = useState()
    const dispatch = useDispatch()
    const [showSettings, setShowSettings] = useState(0)

    const useMountEffect = (fun) =>
        useEffect(fun, [unit, activitiesGrouped, teamGraphTheme])

    const { teamGraphTheme } = useSelector((state) => state.user.config)
    const [colors, setColors] = useState(ThemeProvider(teamGraphTheme))

    var remainingActivities = 0
    var remainingActivitiesSteps = 0
    const showItems = 5

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    const assignTeam = () => {
        dispatch(assignToTeam(token))
    }

    const toggleSettings = (event) => {
        event.type === 'mouseenter' ? setShowSettings(1) : setShowSettings(0)
    }

    const sortSummary = () => {
        setColors(ThemeProvider(teamGraphTheme))

        if (activitiesGrouped !== undefined) {
            if (unit === 'Km' || unit === 'Steps' || unit === 'Points') {
                setSummary(
                    [...activitiesGrouped].sort((a, b) =>
                        a.stepsEquivalent > b.stepsEquivalent ? -1 : 1
                    )
                )
            } else if (unit === 'Time') {
                setSummary(
                    [...activitiesGrouped].sort((a, b) =>
                        a.value > b.value ? -1 : 1
                    )
                )
            }
        }
    }

    useMountEffect(sortSummary)

    if (!summary || summary.length === 0) {
        return (
            <div className="empty-team-box">
                <div
                    className="empty-team"
                    style={{
                        textAlign: 'center',
                        fontSize: 'small',
                    }}
                >
                    {team?.name === undefined || team?.name === null
                        ? `You have not been assigned a team yet`
                        : `${team.name} has not registered any activities during this period`}
                </div>
            </div>
        )
    }
    return (
        <>
            <div
                className={classes.heroContent}
                onMouseEnter={(e) => toggleSettings(e)}
                onMouseLeave={(e) => toggleSettings(e)}
            >
                <div
                    className="row"
                    style={{ marginLeft: '10px', marginRight: '10px' }}
                >
                    <div
                        className="col"
                        style={{
                            textAlign: 'center',
                            marginBottom: '15px',
                            fontSize: 'small',
                            color: '#9D9D9D',
                        }}
                    >
                        <strong>{team.name}</strong>
                        <br />
                        <i>
                            (Total <TitleDisplayer unit={unit} />:{' '}
                            <UnitDisplayer
                                unit={unit}
                                valueInTime={data?.totalValue}
                                valueInSteps={data?.totalSteps}
                            />
                            )
                        </i>
                        <div
                            style={{
                                position: 'absolute',
                                marginBottom: '15px',
                                fontSize: 'small',
                                color: '#9D9D9D',
                                top: 0,
                                right: '10px',
                            }}
                        >
                            <SettingsGraph area="Team" show={showSettings} />
                        </div>
                    </div>
                </div>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    {summary && (
                        <TeamSummaryGraph summary={summary} COLORS={colors} />
                    )}
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                >
                    <Grid item xs={10}>
                        <div className="container">
                            <div className="row summary-titles">
                                <div
                                    className="col col-1"
                                    style={{ padding: '0' }}
                                ></div>
                                <div className="col col-7">Activity</div>
                                <div className="col col-4">
                                    <TitleDisplayer unit={unit} />
                                </div>
                            </div>
                        </div>

                        {summary?.map((entry, index) => {
                            if (index > showItems) {
                                remainingActivitiesSteps =
                                    remainingActivitiesSteps +
                                    entry.stepsEquivalent
                                remainingActivities =
                                    remainingActivities + entry.value
                                if (index === summary.length - 1) {
                                    return (
                                        <div className="container" key={index}>
                                            <div
                                                className="row"
                                                id={`team-summary-item-${index}`}
                                            >
                                                <div
                                                    className="col col-1"
                                                    style={{
                                                        padding: '0',
                                                        color: colors[
                                                            index %
                                                                colors.length
                                                        ],
                                                        bottom: '1px',
                                                    }}
                                                ></div>
                                                <div className="col col-7 activity-type-title">
                                                    {summary.length -
                                                        showItems -
                                                        1}{' '}
                                                    more activities
                                                </div>
                                                <div
                                                    className="col col-4"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    <UnitDisplayer
                                                        unit={unit}
                                                        valueInTime={
                                                            remainingActivities
                                                        }
                                                        valueInSteps={
                                                            remainingActivitiesSteps
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return null
                            }
                            return (
                                <div className="container" key={index}>
                                    <div
                                        className="row"
                                        id={`team-summary-item-${index}`}
                                    >
                                        <div
                                            className="col col-1"
                                            style={{
                                                padding: '0',
                                                color: colors[
                                                    index % colors.length
                                                ],
                                                bottom: '1px',
                                            }}
                                        >
                                            <FiberManualRecordIcon />
                                        </div>
                                        <div className="col col-7 activity-type-title">
                                            {entry.key}
                                        </div>
                                        <div
                                            className="col col-4"
                                            style={{ whiteSpace: 'nowrap' }}
                                        >
                                            <UnitDisplayer
                                                unit={unit}
                                                valueInTime={entry.value}
                                                valueInSteps={
                                                    entry.stepsEquivalent
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Grid>
                </Grid>
            </div>
        </>
    )
})
