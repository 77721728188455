import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export const ClearTeamsModal = (props) => {
    const [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (answer) => {
        if (answer === 'yes') {
            props.onClearAllTeams()
        }
        setOpen(false)
    }

    return (
        <div>
            <div
                style={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    paddingRight: '10px',
                }}
            >
                <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={handleClickOpen}
                >
                    Clear all teams
                </Button>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <span role="img" aria-label="Warning">
                        ⚠️
                    </span>{' '}
                    {'Clear all teams?'}{' '}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        All users will have their team cleared. This{' '}
                        <i>cannot</i> be undone. Are you sure this is what you
                        want?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleClose('yes')}
                        color="secondary"
                        autoFocus
                    >
                        Yes
                    </Button>
                    <Button onClick={handleClose} color="white">
                        No, cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
