import React, { Fragment } from 'react'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { useSelector } from 'react-redux'
import { UnitDisplayer } from '../../components/UnitDisplayer'
import { MoreMenu } from './moreMenu'
import { RenderActivityIcon } from './activityIcon'
import Tooltip from '@material-ui/core/Tooltip'
import ErrorIcon from '@material-ui/icons/Error'

export const MyActivitiesHistory = () => {
    const COLORS = [
        '#FCD116',
        '#FFB900',
        '#FF8C00',
        '#DD5900',
        '#EB3C00',
        '#E81123',
        '#BA141A',
    ]

    const { unit } = useSelector((state) => state.user.config)
    const { days } = useSelector((state) => state.activity.data)

    const renderExclamationMark = (type) => {
        if (type === 'Other') {
            return (
                <Tooltip
                    color="secondary"
                    title={
                        <div style={{ fontSize: 'small' }}>
                            {
                                'This activity was not recognized by our system. Consider changing the activity type by using the edit menu.'
                            }
                        </div>
                    }
                    arrow
                >
                    <ErrorIcon />
                </Tooltip>
            )
        }
    }

    const historyList = () => {
        if (days.length === 0) {
            return <></>
        } else {
            return [...days]
                .sort((a, b) => (a.date > b.date ? 1 : -1))
                .map((day, index) => {
                    return (
                        <div className="activity-instance" key={index}>
                            <div className="activity-timeline">
                                <div className="row">
                                    <div className="col">
                                        <FiberManualRecordIcon
                                            style={{
                                                fontSize: '0.8rem',
                                            }}
                                        />
                                        &nbsp;
                                        {new Date(day.date).toLocaleDateString(
                                            'en-US',
                                            {
                                                dateStyle: 'long',
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="container activity-overview">
                                <div className="row">
                                    <div className="col">
                                        {day?.historyItems?.map(
                                            (item, index) => {
                                                return (
                                                    <Fragment key={index}>
                                                        <div className="container activity-single">
                                                            <div className="row">
                                                                <div
                                                                    className="col col-1"
                                                                    style={{
                                                                        color:
                                                                            COLORS[
                                                                                index %
                                                                                    COLORS.length
                                                                            ],
                                                                        fontSize:
                                                                            '20px',
                                                                    }}
                                                                >
                                                                    <RenderActivityIcon
                                                                        activity={
                                                                            item?.name
                                                                        }
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="col col-5"
                                                                    style={{
                                                                        paddingLeft:
                                                                            '12px',
                                                                        paddingRight:
                                                                            '2px',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        height:
                                                                            '36px',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            overflow:
                                                                                'hidden',
                                                                            whiteSpace:
                                                                                'nowrap',
                                                                            textOverflow:
                                                                                'ellipsis',
                                                                        }}
                                                                    >
                                                                        {
                                                                            item?.name
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className="col col-2"
                                                                    style={{
                                                                        padding:
                                                                            '0',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        height:
                                                                            '36px',
                                                                    }}
                                                                >
                                                                    <strong>
                                                                        <UnitDisplayer
                                                                            unit={
                                                                                unit
                                                                            }
                                                                            valueInTime={
                                                                                item?.value
                                                                            }
                                                                            valueInSteps={
                                                                                item?.stepsEquivalent
                                                                            }
                                                                        />
                                                                    </strong>{' '}
                                                                </div>

                                                                <div
                                                                    className="col col-1"
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        height:
                                                                            '36px',
                                                                        top:
                                                                            '0',
                                                                        left:
                                                                            '-10px',
                                                                        color:
                                                                            '#555',
                                                                    }}
                                                                >
                                                                    {item.source !==
                                                                    undefined
                                                                        ? item?.source?.substring(
                                                                              0,
                                                                              1
                                                                          )
                                                                        : ''}
                                                                </div>
                                                                <div
                                                                    className="col col-1"
                                                                    style={{
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        height:
                                                                            '36px',
                                                                        top:
                                                                            '-2px',
                                                                        left:
                                                                            '-13px',
                                                                        color:
                                                                            '#555',
                                                                    }}
                                                                >
                                                                    {renderExclamationMark(
                                                                        item.name
                                                                    )}
                                                                </div>
                                                                <div
                                                                    className="col col-1"
                                                                    style={{
                                                                        paddingRight:
                                                                            '0',
                                                                        display:
                                                                            'flex',
                                                                        alignItems:
                                                                            'center',
                                                                        height:
                                                                            '36px',
                                                                    }}
                                                                >
                                                                    <MoreMenu
                                                                        id={
                                                                            item.id
                                                                        }
                                                                        activityId={
                                                                            item.activityId
                                                                        }
                                                                        activityName={
                                                                            item.name
                                                                        }
                                                                        date={
                                                                            day.date
                                                                        }
                                                                        duration={
                                                                            item?.value
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                .reverse()
        }
    }

    return (
        <>
            {/* {data ?? 'No history to show'} */}
            {days && historyList()}
        </>
    )
}
