import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { UnitDisplayer } from '../../components/UnitDisplayer'
import { TitleDisplayer } from '../../components/TitleDisplayer'
import Grid from '@material-ui/core/Grid'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { TeamSummaryGraph } from '../team/teamSummaryGraph'
import { AddActivity } from '../activity/addActivity'
import { SettingsGraph } from './SettingsGraph'
import { ThemeProvider } from '../../components/ThemeProvider'
import { ViewMe } from './ViewMe'

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: theme.spacing(2),
    },
    heroContent: {
        backgroundColor: '#0b1222',
        padding: theme.spacing(5, 0, 0),
    },
}))

export const PersonalPageSummary = memo((props) => {
    const classes = useStyles()
    const [personalSummary, setPersonalSummary] = useState()
    const useMountEffect = (fun) =>
        useEffect(fun, [unit, summary, personalGraphTheme])
    var remainingActivities = 0
    var remainingActivitiesSteps = 0
    const showItems = 3
    const { summary } = useSelector((state) => state.userSummary)
    const { personalGraphTheme } = useSelector((state) => state.user.config)
    const [colors, setColors] = useState(ThemeProvider(personalGraphTheme))
    const { unit } = useSelector((state) => state.user.config)
    const [showSettings, setShowSettings] = useState(0)
    const [active, setActive] = useState(false)

    const toggleActive = () => {
        setActive(!active)
    }

    const toggleSettings = (event) => {
        event.type === 'mouseenter' ? setShowSettings(1) : setShowSettings(0)
    }

    const sortSummary = () => {
        setColors(ThemeProvider(personalGraphTheme))

        if (summary.summary !== undefined) {
            if (unit === 'Km' || unit === 'Steps' || unit === 'Points') {
                setPersonalSummary(
                    [...summary.summary].sort((a, b) =>
                        a.stepsEquivalent > b.stepsEquivalent ? -1 : 1
                    )
                )
            } else if (unit === 'Time') {
                setPersonalSummary(
                    [...summary.summary].sort((a, b) =>
                        a.value > b.value ? -1 : 1
                    )
                )
            }
        }
    }

    useMountEffect(sortSummary)

    if (!personalSummary || personalSummary.length === 0) {
        return (
            <div className="container" style={{ paddingTop: '75px' }}>
                <div className="row">
                    <div
                        className="col empty-you"
                        style={{ paddingTop: '7px' }}
                    >
                        <div
                            className="empty-team"
                            style={{
                                textAlign: 'center',
                                fontSize: 'small',
                            }}
                        >
                            No data on you yet.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col empty-you">
                        <AddActivity />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={`me-container ${active === true ? 'active' : ''}`}>
            <div className={` ${active === true ? 'active' : ''}`}>
                <div
                    className={classes.heroContent}
                    onMouseEnter={(e) => toggleSettings(e)}
                    onMouseLeave={(e) => toggleSettings(e)}
                >
                    <div className="row">
                        <div
                            className="col"
                            style={{
                                textAlign: 'center',
                                marginBottom: '15px',
                                fontSize: 'small',
                                color: '#9D9D9D',
                            }}
                        >
                            <strong>You</strong>
                            <br />
                            <i>
                                (Total <TitleDisplayer unit={unit} />:{' '}
                                <UnitDisplayer
                                    unit={unit}
                                    valueInTime={summary?.totalValue}
                                    valueInSteps={summary?.totalSteps}
                                />
                                )
                            </i>
                            <div
                                style={{
                                    position: 'absolute',
                                    marginBottom: '15px',
                                    fontSize: 'small',
                                    color: '#9D9D9D',
                                    top: 0,
                                    right: '30px',
                                }}
                            >
                                <SettingsGraph
                                    area="Personal"
                                    show={showSettings}
                                />
                                {/* <ViewMe
                                    area="Team"
                                    show={showSettings}
                                    toggleActive={toggleActive}
                                /> */}
                            </div>
                        </div>
                    </div>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        {personalSummary && (
                            <TeamSummaryGraph
                                summary={personalSummary}
                                COLORS={colors}
                            />
                        )}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                    >
                        <Grid item xs={10}>
                            <div
                                className="container"
                                style={{ textAlign: 'center' }}
                            >
                                <AddActivity />

                                <div className="row summary-titles">
                                    <div
                                        className="col col-1"
                                        style={{ padding: '0' }}
                                    ></div>
                                    <div
                                        className="col col-7"
                                        style={{ textAlign: 'left' }}
                                    >
                                        Activity
                                    </div>
                                    <div
                                        className="col col-4"
                                        style={{ textAlign: 'left' }}
                                    >
                                        <TitleDisplayer unit={unit} />
                                    </div>
                                </div>
                            </div>

                            {personalSummary?.map((entry, index) => {
                                if (index > showItems) {
                                    remainingActivitiesSteps =
                                        remainingActivitiesSteps +
                                        entry.stepsEquivalent
                                    remainingActivities =
                                        remainingActivities + entry.value
                                    if (index === personalSummary.length - 1) {
                                        return (
                                            <div
                                                className="container"
                                                key={index}
                                            >
                                                <div
                                                    className="row"
                                                    id={`team-summary-item-${index}`}
                                                >
                                                    <div
                                                        className="col col-1"
                                                        style={{
                                                            padding: '0',
                                                            color: colors[
                                                                index %
                                                                    colors.length
                                                            ],
                                                            bottom: '1px',
                                                        }}
                                                    ></div>
                                                    <div className="col col-7 activity-type-title">
                                                        {personalSummary.length -
                                                            showItems -
                                                            1}{' '}
                                                        more activities
                                                    </div>
                                                    <div
                                                        className="col col-4"
                                                        style={{
                                                            whiteSpace:
                                                                'nowrap',
                                                        }}
                                                    >
                                                        <UnitDisplayer
                                                            unit={unit}
                                                            valueInTime={
                                                                remainingActivities
                                                            }
                                                            valueInSteps={
                                                                remainingActivitiesSteps
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    return null
                                }
                                return (
                                    <div className="container" key={index}>
                                        <div
                                            className="row"
                                            id={`team-summary-item-${index}`}
                                        >
                                            <div
                                                className="col col-1"
                                                style={{
                                                    padding: '0',
                                                    color: colors[
                                                        index % colors.length
                                                    ],
                                                    bottom: '1px',
                                                }}
                                            >
                                                <FiberManualRecordIcon />
                                            </div>
                                            <div className="col col-7 activity-type-title">
                                                {entry.key}
                                            </div>
                                            <div
                                                className="col col-4"
                                                style={{ whiteSpace: 'nowrap' }}
                                            >
                                                <UnitDisplayer
                                                    unit={unit}
                                                    valueInTime={entry.value}
                                                    valueInSteps={
                                                        entry.stepsEquivalent
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
})
