import firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: process.env.REACT_APP_BACK_F_apiKey,
    authDomain: process.env.REACT_APP_BACK_F_authDomain,
    projectId: process.env.REACT_APP_BACK_F_projectId,
    storageBucket: process.env.REACT_APP_BACK_F_storageBucket,
    messagingSenderId: process.env.REACT_APP_BACK_F_messagingSenderId,
    appId: process.env.REACT_APP_BACK_F_appId,
    measurementId: process.env.REACT_APP_BACK_F_measurementId,
}

export const googleProvider = new firebase.auth.GoogleAuthProvider()

export const myFirebase = firebase.initializeApp(firebaseConfig)
