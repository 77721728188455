import { myFirebase, googleProvider } from './Firebase'
import {
    requestLogin,
    successLogin,
    failureLogin,
    requestLogout,
    successLogout,
    failureLogout,
    requestVerify,
    successVerify,
    requestSignUp,
    successSignUp,
    loginCompleted,
} from './authSlice'

import {
    getAllCategories,
    postActivityHistory,
    getAggregatedData,
    getUserConfig,
    setUnit,
    getCompany,
    getTeamDataGrouped,
    assignTeam,
    deleteActivityHistory,
    getGroupedActivityHistory,
    switchFavorite,
    getSummaryActivityHistory,
    switchOnboarding,
    putActivityHistory,
    setTheme,
    getPeriodData,
} from '../api/api'

import {
    requestCategories,
    categoriesFetched,
} from '../../pages/category/categorySlice'

import {
    requestPeriodData,
    successPeriodData,
} from '../../pages/period/periodSlice'

import {
    postData,
    postDataFailure,
    fetchData,
    fetchDataSuccess,
} from '../../pages/activity/activitySlice'

import {
    requestUserConfig,
    requestUserConfigSuccess,
} from '../../pages/user/userSlice'

import { requestUserSummarySuccess } from '../../pages/user/userSummarySlice'

import {
    requestTeams,
    requestTeamDataSuccess,
    assigningTeam,
    assignedToTeam,
} from '../../pages/team/teamSlice'

import { successCompanyData } from '../../pages/company/companySlice'

export const assignToTeam = (token) => (dispatch) => {
    dispatch(assigningTeam())
    assignTeam(token)
        .then((team) => {
            dispatch(assignedToTeam(team))
            getUserConfig(token)
                .then((config) => {
                    dispatch(requestUserConfigSuccess(config))
                })
                .catch((error) => {
                    //Do something with the error if you want!
                    dispatch(postDataFailure())
                })
        })
        .catch((error) => {
            //Do something with the error if you want!
            dispatch(postDataFailure())
        })
}

export const fetchPeriodData = (token) => {
    return (dispatch) => {
        return getPeriodData(token)
            .then((data) => {
                dispatch(successPeriodData(data))
            })
            .catch((error) => {
                //Do something with the error if you want!
                dispatch(postDataFailure())
            })
    }
}

export const fetchCompanyData =
    (token, registrationStart, registrationEnd) => (dispatch) => {
        dispatch(requestTeams())
        getCompany(token, registrationStart, registrationEnd)
            .then((data) => {
                dispatch(successCompanyData(data))
            })
            .catch((error) => {
                //Do something with the error if you want!
                dispatch(postDataFailure())
            })
    }

export const fetchTeamData =
    (token, registrationStart, registrationEnd) => (dispatch) => {
        dispatch(requestTeams())
        getTeamDataGrouped(token, registrationStart, registrationEnd)
            .then((data) => {
                dispatch(requestTeamDataSuccess(data))
            })
            .catch((error) => {
                //Do something with the error if you want!
                dispatch(postDataFailure())
            })
    }

export const fetchUserConfig = (token) => {
    return (dispatch) => {
        dispatch(requestUserConfig())
        return getUserConfig(token)
            .then((config) => {
                dispatch(requestUserConfigSuccess(config))
                return config
            })
            .catch((error) => {
                console.log('Could not fetch user config')
            })
    }
}

export const fetchAggregatedData = (token) => (dispatch) => {
    dispatch(fetchData())
    getAggregatedData(token)
        .then((aggregatedData) => {
            dispatch(fetchDataSuccess(aggregatedData))
        })
        .catch((error) => {
            //Do something with the error if you want!
            dispatch(postDataFailure())
        })
}

export const fetchGroupedActivityHistory =
    (token, registrationStart, registrationEnd) => (dispatch) => {
        dispatch(fetchData())
        getGroupedActivityHistory(token, registrationStart, registrationEnd)
            .then((groupedData) => {
                dispatch(fetchDataSuccess(groupedData))
                getSummaryActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
                    .then((summarizedData) => {
                        dispatch(requestUserSummarySuccess(summarizedData))
                    })
                    .catch((error) => {
                        console.log('Error fetching summary')
                    })
            })
            .catch((error) => {
                //Do something with the error if you want!
                dispatch(postDataFailure())
            })
    }

export const fetchCategories = (token) => (dispatch) => {
    dispatch(requestCategories())
    getAllCategories(token)
        .then((categories) => {
            dispatch(categoriesFetched(JSON.stringify(categories)))
        })
        .catch((error) => {
            //Do something with the error if you want!
            dispatch(postDataFailure())
        })
}

export const toggleFavorite = (token, activityId) => (dispatch) => {
    switchFavorite(token, activityId)
        .then((result) => {
            getAllCategories(token)
                .then((categories) => {
                    dispatch(categoriesFetched(JSON.stringify(categories)))
                })
                .catch((error) => {
                    //Do something with the error if you want!
                    dispatch(postDataFailure())
                })
        })
        .catch((error) => {
            //Do something with the error if you want!
            console.log(error)
        })
}

export const toggleOnboarding = (token) => {
    switchOnboarding(token)
        .then((result) => {
            console.log('OK')
        })
        .catch((error) => {
            //Do something with the error if you want!
            console.log(error)
        })
}

export const changeUnit = (token, unit) => {
    return (dispatch) => {
        return setUnit(token, unit)
            .then((result) => {
                dispatch(fetchUserConfig(token))
            })
            .catch((error) => {
                //Do something with the error if you want!
                console.log(error)
            })
    }
}

export const changeTheme = (token, area, theme) => {
    return (dispatch) => {
        return setTheme(token, area, theme)
            .then((result) => {
                dispatch(fetchUserConfig(token))
            })
            .catch((error) => {
                //Do something with the error if you want!
                console.log(error)
            })
    }
}

export const updateActivityHistory = (
    id,
    activityId,
    duration,
    date,
    token,
    registrationStart,
    registrationEnd
) => {
    return (dispatch) => {
        return putActivityHistory(id, activityId, duration, date, token)
            .then((response) => {
                dispatch(fetchData())
                dispatch(
                    fetchTeamData(token, registrationStart, registrationEnd)
                )
                getSummaryActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
                    .then((summarizedData) => {
                        dispatch(requestUserSummarySuccess(summarizedData))
                    })
                    .catch((error) => {
                        console.log('Error fetching summary')
                    })
                getGroupedActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
                    .then((activities) => {
                        dispatch(fetchDataSuccess(activities))
                    })
                    .catch((error) => {
                        //Do something with the error if you want!
                        dispatch(postDataFailure())
                    })
            })
            .catch((error) => {
                //Do something with the error if you want!
                dispatch(postDataFailure())
            })
    }
}

export const addActivityHistory = (
    selectedActivity,
    duration,
    date,
    token,
    registrationStart,
    registrationEnd
) => {
    return (dispatch) => {
        dispatch(postData())
        return postActivityHistory(selectedActivity, duration, date, token)
            .then((response) => {
                dispatch(fetchData())
                dispatch(
                    fetchTeamData(token, registrationStart, registrationEnd)
                )
                dispatch(
                    fetchCompanyData(token, registrationStart, registrationEnd)
                )
                getSummaryActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
                    .then((summarizedData) => {
                        dispatch(requestUserSummarySuccess(summarizedData))
                    })
                    .catch((error) => {
                        console.log('Error fetching summary')
                    })
                getGroupedActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
                    .then((activities) => {
                        dispatch(fetchDataSuccess(activities))
                    })
                    .catch((error) => {
                        //Do something with the error if you want!
                        dispatch(postDataFailure())
                    })
            })
            .catch((error) => {
                //Do something with the error if you want!
                dispatch(postDataFailure())
            })
    }
}

export const removeActivityHistory = (
    id,
    token,
    registrationStart,
    registrationEnd
) => {
    return (dispatch) => {
        dispatch(postData())
        return deleteActivityHistory(id, token)
            .then((response) => {
                dispatch(fetchData())
                dispatch(fetchTeamData(token))
                getSummaryActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
                    .then((summarizedData) => {
                        dispatch(requestUserSummarySuccess(summarizedData))
                    })
                    .catch((error) => {
                        console.log('Error fetching summary')
                    })
                getGroupedActivityHistory(
                    token,
                    registrationStart,
                    registrationEnd
                )
                    .then((activities) => {
                        dispatch(fetchDataSuccess(activities))
                    })
                    .catch((error) => {
                        //Do something with the error if you want!
                        dispatch(postDataFailure())
                    })
            })
            .catch((error) => {
                //Do something with the error if you want!
                dispatch(postDataFailure())
            })
    }
}

export const signUpUser = (email, password, name) => {
    return (dispatch) => {
        dispatch(requestSignUp())
        return myFirebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {
                debugger
                result.user
                    // Does this work?
                    .updateProfile({
                        displayName: name,
                    })
                    .then((response) => {
                        dispatch(successSignUp())
                    })
                    .catch((error) => {
                        return error
                    })
            })
            .then(() => {
                myFirebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((token) => {
                        dispatch(loginUserEmail(email, password))
                    })
            })
            .catch((error) => {
                return error
            })
    }
}

export const loginUserEmail = (email, password) => {
    return (dispatch) => {
        dispatch(requestLogin())
        return myFirebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
                dispatch(successLogin(JSON.stringify(result.user)))
            })
            .catch((error) => {
                dispatch(failureLogin())
                return error
            })
    }
}

export const resetPassword = (email) => {
    return (dispatch) => {
        return myFirebase
            .auth()
            .sendPasswordResetEmail(email)
            .then(() => {})
            .catch((error) => {
                console.log(error)
            })
    }
}

export const loginUserGoogle = () => {
    return (dispatch) => {
        dispatch(requestLogin())
        return myFirebase
            .auth()
            .signInWithRedirect(googleProvider)
            .then(() => {
                dispatch(loginCompleted())
            })
            .catch((error) => {
                console.log(error)
                dispatch(failureLogin())
            })
    }
}

export const logoutUser = () => (dispatch) => {
    dispatch(requestLogout())
    myFirebase
        .auth()
        .signOut()
        .then(() => {
            dispatch(successLogout())
        })
        .catch((error) => {
            //Do something with the error if you want!
            dispatch(failureLogout())
        })
}

export const verifyAuth = () => (dispatch) => {
    dispatch(requestVerify())
    myFirebase.auth().onAuthStateChanged((user) => {
        if (user !== null) {
            dispatch(successLogin(JSON.stringify(user)))
        }
        dispatch(successVerify())
    })
}
