import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AccountSettings } from './AccountSettings/accountSettings'
import { UserSettings } from './UserSettings/userSettings'
import { Integrations } from './integrations'
import { fetchUserConfig } from '../../utils/auth/thunks'
import Box from '@material-ui/core/Box'

export const Settings = () => {
    const dispatch = useDispatch()

    const { email } = useSelector((state) => state.auth.user)

    const token = useSelector(
        (state) => state.auth.user.stsTokenManager.accessToken
    )

    useEffect(() => {
        dispatch(fetchUserConfig(token, email))
    }, [dispatch, token, email])

    return (
        <div style={{ minHeight: '100vh' }}>
            <Box pt={8}>
                <AccountSettings />
                <UserSettings />
                <Integrations />
            </Box>
        </div>
    )
}
