import { createSlice } from '@reduxjs/toolkit'

const activitySlice = createSlice({
    name: 'activity',
    initialState: {
        isLoading: false,
        fetchSuccess: false,
        fetchError: false,
        error: {},
        data: [{}],
        summary: [{}],
    },
    reducers: {
        postData(state) {
            state.isLoading = true
            state.fetchSuccess = false
        },
        postDataSuccess(state) {
            state.isLoading = false
            state.fetchSuccess = true
        },
        fetchDataSuccess(state, action) {
            state.isLoading = false
            state.fetchSuccess = true
            state.data = action.payload
        },
        fetchSummarySuccess(state, action) {
            state.summary = action.payload
        },
        postDataFailure(state) {
            state.isLoading = false
            state.fetchSuccess = false
            state.fetchError = true
        },
        fetchData(state) {
            state.isLoading = true
            state.fetchSuccess = false
            state.fetchError = false
        },
    },
})

export const {
    postData,
    postDataSuccess,
    postDataFailure,
    fetchDataSuccess,
    fetchData,
    addActivity,
    fetchSummarySuccess,
} = activitySlice.actions
export default activitySlice.reducer
