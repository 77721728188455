import 'date-fns'
import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import Chip from '@material-ui/core/Chip'

export const ActivityDatePicker = (props) => {
    const [selectedDate, setSelectedDate] = useState(new Date())

    const handleDateChange = (date) => {
        setSelectedDate(new Date(date))
        props.onDateChange(new Date(date))
    }

    useEffect(() => {
        if (props.date !== undefined) setSelectedDate(props.date)
    }, [props.date])

    return (
        <div className="row">
            <div className="container">
                <div className="row">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container>
                            <KeyboardDatePicker
                                autoOk={true}
                                disableToolbar
                                inputVariant="outlined"
                                format="dd.MM.yyyy"
                                margin="normal"
                                id="date-picker-inline"
                                label="Date"
                                value={selectedDate}
                                onChange={handleDateChange}
                                color="secondary"
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    color: 'secondary',
                                }}
                            />
                        </Grid>
                    </MuiPickersUtilsProvider>
                </div>
                <div className="row time-row">
                    <div className="sub-cell">
                        <Chip
                            color="primary"
                            size="small"
                            label="2 days ago"
                            onClick={() => {
                                var d = new Date()
                                var yesteryesterday = d.setDate(d.getDate() - 2)
                                handleDateChange(yesteryesterday)
                            }}
                        />
                    </div>
                    <div className="sub-cell">
                        <Chip
                            color="primary"
                            size="small"
                            label="Yesterday"
                            onClick={() => {
                                var d = new Date()
                                var yesterday = d.setDate(d.getDate() - 1)
                                handleDateChange(yesterday)
                            }}
                        />
                    </div>
                    <div className="sub-cell">
                        <Chip
                            color="primary"
                            size="small"
                            label="Today"
                            onClick={() => handleDateChange(new Date())}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
