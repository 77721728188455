import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import { Redirect } from 'react-router-dom'
import { myFirebase } from '../../utils/auth/Firebase'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import { toast } from 'react-toastify'
import { Error, Check } from '../../components/ToastContent'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export const HandleVerifyEmail = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const mode = urlParams.get('mode')
    const oobCode = urlParams.get('oobCode')
    const classes = useStyles()
    const [emailVerified, setEmailVerified] = useState()
    const [passwordReset, setPasswordReset] = useState('')
    const [isLoading] = useState(false)
    const [firstPassword, setFirstPassword] = useState()
    const [secondPassword, setSecondPassword] = useState()

    const onHandleVerifyEmail = (myFirebase, actionCode, continueUrl, lang) => {
        myFirebase
            .auth()
            .applyActionCode(actionCode)
            .then((resp) => {
                setEmailVerified(true)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const onHandleResetPassword = (myFirebase, actionCode) => {
        if (firstPassword !== secondPassword) {
            toast.error(<Error text="The two passwords are not the same" />)
        }
        if (
            firstPassword === '' ||
            secondPassword === '' ||
            firstPassword === ' '
        ) {
            toast.error(<Error text="The password cannot be empty or blank" />)
        } else {
            myFirebase
                .auth()
                .confirmPasswordReset(actionCode, firstPassword)
                .then((resp) => {
                    toast.success(<Check text="Password updated" />)
                    setPasswordReset(true)
                })
                .catch((error) => {
                    toast.error(<Error text={error.message} />)
                })
        }
    }

    const returnToHome = () => {
        return (
            <Redirect
                to={{
                    pathname: '/',
                }}
            />
        )
    }

    const renderResetPassword = () => {
        return (
            <article>
                <div className="pt-5">
                    <div className="d-flex justify-content-center">
                        <h1 className="display-3">New Password</h1>
                    </div>
                </div>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                    paddingBottom: '20px',
                                }}
                            >
                                Type in your new password, at least{' '}
                                <strong>6</strong> characters long
                            </div>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    color="secondary"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="New Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) =>
                                        setFirstPassword(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    color="secondary"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Retype new password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={(e) =>
                                        setSecondPassword(e.target.value)
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Button
                            id="btn-resetpassword"
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={() => {
                                onHandleResetPassword(myFirebase, oobCode)
                            }}
                        >
                            Set new password
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link
                                    href="/"
                                    variant="body2"
                                    color="secondary"
                                >
                                    Go to login
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </article>
        )
    }

    const renderContinue = () => {
        return (
            <article>
                <div className="pt-5">
                    <div className="d-flex justify-content-center">
                        <h2 className="display-3">
                            {mode === 'verifyEmail'
                                ? 'Email verified!'
                                : 'Password has been reset!'}
                        </h2>
                    </div>
                </div>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <div className={classes.paper}>
                        <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                            textAlign="center"
                        >
                            <div style={{ textAlign: 'center' }}>
                                Good work! Now you are ready to go!
                            </div>
                        </Grid>
                        <a href="/">
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={(e) => returnToHome(e)}
                            >
                                Let's start!
                            </Button>
                        </a>
                    </div>
                </Container>
            </article>
        )
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const mode = urlParams.get('mode')
        const oobCode = urlParams.get('oobCode')
        const continueUrl = urlParams.get('continueUrl')

        if (mode === 'verifyEmail') {
            onHandleVerifyEmail(myFirebase, oobCode, continueUrl)
        }
    }, [])

    if (emailVerified || passwordReset) {
        return renderContinue()
    } else {
        if (mode === 'verifyEmail') {
            return <>Verifying email</>
        } else if (mode === 'resetPassword') {
            return renderResetPassword()
        }
    }
}
