import React from 'react'
import { ConfigurationAccordion } from './ConfigurationAccordion'
import { Configuration } from './Configuration'

export const ConfigurationSelector = () => {
    var windowWidth = window.innerWidth

    return (
        <>
            {windowWidth <= 768 && <ConfigurationAccordion />}
            {windowWidth > 768 && <Configuration />}
        </>
    )
}
